import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import '../styles/side-menu-button.css';

function SideMenuButton({ show, onSideMenuToggle }) {
  const hasTooltip = window.innerWidth >= 992 ? 'tooltip' : '';

  const render = () => (
    <div className="console-open-btn">
      <div
        onClick={onSideMenuToggle}
        className={`button small is-dark ${hasTooltip} is-tooltip-left`}
        data-tooltip="Collapse Log"
      >
        <FontAwesomeIcon icon={faTerminal} />
      </div>
    </div>
  );

  return show ? render() : '';
}

export default SideMenuButton;
