import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import App from './App';
import { StateProvider } from './components/stateProvider';

ReactDOM.render(
  <StateProvider>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/:sectionPath?" component={App} />
        <Route exact path="/:sectionPath?/:flowPath?" component={App} />
      </Switch>
    </Router>
  </StateProvider>,
  document.getElementById('root'),
);
