import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import '../styles/forter-logo.css';

function ForterLogo({ show }) {
  const render = () => (
    <div className="powered-by">
      <div>Powered By</div>
      <div className="logo">
        <Logo />
      </div>
    </div>
  );
  return show ? render() : '';
}

export default ForterLogo;
