export default {
  ONE_PLUS: {
    name: 'OnePlus',
    description: 'OnePlus 6T 128GB 8GB Black',
    amount: '1000',
    eurAmount: '840',
    tax: '20',
    eurTax: '16',
    eurShipping: '84',
    shipping: '100',
    image: 'oneplus6t',
  },
  CHARGER: {
    name: 'Wireless Charger',
    description: 'Wireless Charger',
    amount: '60',
    eurAmount: '52',
    tax: '10',
    eurTax: '8',
    shipping: '10',
    eurShipping: '8',
    image: 'wirelessCharger',
  },
  CASE: {
    name: 'Phone Case',
    description: 'Black Rhinoshield shockprof case',
    amount: '10',
    eurAmount: '8',
    tax: '0',
    eurTax: '0',
    shipping: '2',
    eurShipping: '1',
    image: 'case',
  },
};
