import usSeGeneral from '../assets/us_se_general.png';
import deSw from '../assets/de_sw.png';
import ne from '../assets/ne.png';
import ach from '../assets/ach.png';
import affirm from '../assets/affirm.png';
import sofort from '../assets/sofort.png';
import giropay from '../assets/giropay.png';
import dd from '../assets/dd.png';
import paypal from '../assets/paypal.png';
import klarna from '../assets/klarna.png';
import paypalcc from '../assets/paypal_cc.png';
import ideal from '../assets/ideal.png';
import swish from '../assets/swish.png';
import USflag from '../assets/united-states.png';
import GBflag from '../assets/united-kingdom.png';
import DEflag from '../assets/germany.png';
import NLflag from '../assets/netherlands.png';
import SEflag from '../assets/sweden.png';
import LAflag from '../assets/laos.png';
import globeFlag from '../assets/globe.png';

export const LOG_TYPES = {
  BROWSER: 'browser',
  MERCHANT: 'merchant',
  ERROR: 'error',
  PAYLOAD: 'payload',
  FORTER: 'forter',
  BACKGROUND_ACTION: 'background-action',
  USER_ACTION: 'user-action',
};
export const CHALLENGE_TYPES = {
  AJAX_REDIRECT: 'AJAX_REDIRECT',
  MODAL: 'MODAL',
  CHECKOUT: 'CHECKOUT',
};
export const CHALLENGE_POSITIONS = {
  AJAX_REDIRECT: { title: 'Ajax Redirect', value: CHALLENGE_TYPES.AJAX_REDIRECT },
  MODAL: { title: 'Modal', value: CHALLENGE_TYPES.MODAL },
  CHECKOUT: { title: 'Checkout', value: CHALLENGE_TYPES.CHECKOUT },
};
export const DECLINE_RECOVERY_DISPLAY = {
  CONTAINER: 'container',
  MODAL: 'modal',
  POPUP: 'popup',
  CHAT: 'chat',
};
export const PAYMENTS_ENV = {
  STAGING: 'Staging',
  PROD: 'Production',
};
export const NOTIFICATIONS = {
  TRIGGER_CHALLENGE: 'Sending data to trigger challenge...',
  INIT_3DS: 'Initialising 3DS...',
  SENDING_DATA_TO_ISSUER: 'Sending data to issuer...',
  TRIGGER_VERIFY: 'Verifying challenge',
};
export const TABS = {
  FLOWS: { title: 'Flows', icon: 'faAlignJustify' },
  THREE_DS: { title: 'Settings', icon: 'faCog' },
  HINTS: { title: 'Hints', icon: 'faLightbulb' },
};
export const FORTER_DECISIONS = {
  APPROVE: 'APPROVE',
  DECLINE: 'DECLINE',
  NOT_REVIEWED: 'NOT REVIEWED',
};
export const THREE_DS_VERSIONS = {
  THREE_DS_ONE: '1.0.2',
  THREE_DS_TWO: '2.1.0',
};
export const PAGES = {
  DATA_ENTRY: 'data-entry',
  THANK_YOU: 'thank-you',
  CHALLENGE: 'challenge',
};
export const DEMO_PHASES = {
  CHECKOUT: 'checkout',
  THREE_DS: 'three_ds',
  OTP_CHALLENGE: 'otp_challenge',
};

export const DR_SUCCESS_FLOWS = {
  CVV: '112',
  ADDRESS: '351 5th Avenue, New York, NY',
  FIRST_NAME: 'Joy',
  CARD_NUMBER: '4111111111111111',
  PRODUCT: 'CASE',
  EXPIRY: '11/25',
};

export const FLAGS = {
  US: USflag,
  DE: DEflag,
  NL: NLflag,
  GB: GBflag,
  SE: SEflag,
  LA: LAflag,
  OTHER: globeFlag,
};

export const COUNTRIES = {
  US: { name: 'United States', code: 'US' },
  DE: { name: 'Germany', code: 'DE' },
  NL: { name: 'Netherlands', code: 'NL' },
  GB: { name: 'United Kingdom', code: 'GB' },
  SE: { name: 'Sweden', code: 'SE' },
  LA: { name: 'Laos', code: 'LA' },
  OTHER: { name: 'Other country', code: 'OTHER' },
};

export const CURRENCY = {
  USD: { name: 'USD', sign: '$' },
  EUR: { name: 'EUR', sign: '€' },
};

export const PAYMENTS = {
  [COUNTRIES.US.code]: [
    { title: 'Credit Card', icon: usSeGeneral, type: 'CC', checked: true },
    { title: 'Pay with ACH', icon: ach, type: 'ACH', url: 'https://www.ach-payments.com/' },
    { title: 'Pay with Affirm', icon: affirm, type: 'OTHER', url: 'https://www.affirm.com/' },
  ],
  [COUNTRIES.DE.code]: [
    { title: 'Credit Card', icon: deSw, type: 'CC', checked: true },
    { title: 'Pay with Sofort', icon: sofort, type: 'OTHER', url: 'https://www.klarna.com/pay-now/' },
    { title: 'Pay with Giropay', icon: giropay, type: 'OTHER', url: 'https://www.giropay.de/' },
    { title: 'Pay with Direct Debit', icon: dd, type: 'OTHER', url: 'https://www.directdebit.co.uk/Pages/Home.aspx' },
    { title: 'Pay with Paypal', icon: paypal, type: 'OTHER', url: 'https://www.paypal.com/' },
    {
      title: 'Pay with Paypal Credit',
      icon: paypalcc,
      type: 'OTHER',
      url: 'https://www.paypal.com/us/webapps/mpp/paypal-credit',
    },
    { title: 'Pay with Klarna', icon: klarna, type: 'OTHER', url: 'https://www.klarna.com/us/' },
  ],
  [COUNTRIES.NL.code]: [
    { title: 'Pay with iDeal', icon: ideal, type: 'OTHER', url: 'https://www.ideal.nl/en/', checked: true },
    { title: 'Credit Card', icon: ne, type: 'CC' },
    { title: 'Pay with Direct Debit', icon: dd, type: 'OTHER', url: 'https://www.directdebit.co.uk/Pages/Home.aspx' },
    { title: 'Pay with Paypal', icon: paypal, type: 'OTHER', url: 'https://www.paypal.com/' },
    { title: 'Pay with Klarna', icon: klarna, type: 'OTHER', url: 'https://www.klarna.com/us/' },
    {
      title: 'Pay with Paypal Credit',
      icon: paypalcc,
      type: 'OTHER',
      url: 'https://www.paypal.com/us/webapps/mpp/paypal-credit',
    },
  ],
  [COUNTRIES.GB.code]: [
    { title: 'Credit Card', icon: usSeGeneral, type: 'CC', checked: true },
    { title: 'Pay with Paypal', icon: paypal, type: 'OTHER', url: 'https://www.paypal.com/' },
    { title: 'Pay with Direct Debit', icon: dd, type: 'OTHER', url: 'https://www.directdebit.co.uk/Pages/Home.aspx' },
    { title: 'Pay with Klarna', icon: klarna, type: 'OTHER', url: 'https://www.klarna.com/us/' },
  ],
  [COUNTRIES.SE.code]: [
    { title: 'Credit Card', icon: deSw, type: 'CC', checked: true },
    { title: 'Pay with Swish', icon: swish, type: 'OTHER', url: 'https://www.swish.nu/' },
    { title: 'Pay with Direct Debit', icon: dd, type: 'OTHER', url: 'https://www.directdebit.co.uk/Pages/Home.aspx' },
    { title: 'Pay with Paypal', icon: paypal, type: 'OTHER', url: 'https://www.paypal.com/' },
    { title: 'Pay with Klarna', icon: klarna, type: 'OTHER', url: 'https://www.klarna.com/us/' },
    {
      title: 'Pay with Paypal Credit',
      icon: paypalcc,
      type: 'OTHER',
      url: 'https://www.paypal.com/us/webapps/mpp/paypal-credit',
    },
  ],
  [COUNTRIES.LA.code]: [{ title: 'Credit Card', icon: deSw, type: 'CC', checked: true }],
  [COUNTRIES.OTHER.code]: [
    { title: 'Credit Card', icon: usSeGeneral, type: 'CC', checked: true },
    { title: 'Pay with Paypal', icon: paypal, type: 'OTHER', url: 'https://www.paypal.com/' },
    { title: 'Pay with Klarna', icon: klarna, type: 'OTHER', url: 'https://www.klarna.com/us/' },
    {
      title: 'Pay with Paypal Credit',
      icon: paypalcc,
      type: 'OTHER',
      url: 'https://www.paypal.com/us/webapps/mpp/paypal-credit',
    },
  ],
};
export const CREDIT_CARD_MASKING = {
  amex: {
    pattern: /^3[47]/,
    valid_length: [15],
    icon: 'faCcAmex',
  },
  diners_club_carte_blanche: {
    pattern: /^30[0-5]/,
    valid_length: [14],
    icon: 'faCcDinersClub',
  },
  diners_club_international: {
    pattern: /^36/,
    valid_length: [14],
    icon: 'faCcDinersClub',
  },
  jcb: {
    pattern: /^35(2[89]|[3-8][0-9])/,
    valid_length: [16],
    icon: 'faCcJcb',
  },
  visa_electron: {
    pattern: /^(4026|417500|4508|4844|491(3|7))/,
    valid_length: [16],
    icon: 'faCcVisa',
  },
  visa: {
    pattern: /^4/,
    valid_length: [16],
    icon: 'faCcVisa',
  },
  mastercard: {
    pattern: /^5[1-5]/,
    valid_length: [16],
    icon: 'faCcMastercard',
  },
  discover: {
    pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    valid_length: [16],
    icon: 'faCcDiscover',
  },
};
