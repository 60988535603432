export default {
  getFormInitObj: () => ({
    cc: '',
    expiry: '',
    cvv: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    zipCode: '',
    country: 'US',
  }),
  getFormResetObj: () => ({
    cc: '',
    expiry: '',
    cvv: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    zipCode: '',
  }),
};
