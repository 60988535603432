import React, { useState } from 'react';

const MainContext = React.createContext({});

export function useMainContext() {
  const context = React.useContext(MainContext);

  return context;
}

export const StateProvider = ({ children }) => {
  const [isSavePaymentsVisible, setIsSavePaymentsVisible] = useState(false);
  const [showSavedPayments, setShowSavedPayments] = useState(false);
  const [hideCVV, setHideCVV] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState(0);

  return (
    <MainContext.Provider
      value={{
        isSavePaymentsVisible,
        setIsSavePaymentsVisible,
        showSavedPayments,
        setShowSavedPayments,
        defaultPayment,
        setDefaultPayment,
        hideCVV,
        setHideCVV,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
