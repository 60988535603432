import React from 'react';
import mc from '../assets/mc.png';
import visa from '../assets/visa.png';
import FormField from './form-field';
import { useMainContext } from './stateProvider';

export default function SavedPayments({ savedPayment, cvv, onFormInputChange, inputErrors }) {
  const { setIsSavePaymentsVisible, hideCVV } = useMainContext();
  const SAVED_PAYMENTS = {
    VISA: { label: 'Visa', icon: visa },
    MC: { label: 'Mastercard', icon: mc },
  };
  const { label, icon } = SAVED_PAYMENTS[savedPayment];

  const onCVVInput = (e) => {
    const numStr = /^-?(\d+\.?\d*)$|(\d*\.?\d+)$/;
    const res = numStr.test(e.target.value);
    if (!res && e.target.value !== '') {
      e.preventDefault();
    }
  };

  return (
    <div className="saved-payment-container">
      <div className="field">
        <label className="form-field-title">Saved Cards</label>
        <div className="control has-icons-left">
          <div className="select is-small">
            <select defaultValue="VISA">
              <option value="VISA">XXXX-XXXX-XXXX-2354</option>
              <option value="MC">XXXX-XXXX-XXXX-8759</option>
            </select>
          </div>
          <div className="icon is-left">
            <img className="savedPaymentImg" src={icon} alt={label} />
          </div>
        </div>
      </div>
      <div>
        {hideCVV ? (
          ''
        ) : (
          <FormField
            onInput={onCVVInput}
            errors={inputErrors}
            value={cvv}
            maxLength="4"
            onChange={onFormInputChange}
            placeholder="cvv"
            id="cvv"
          >
            CVV
          </FormField>
        )}
      </div>
      <div
        className="text-btn"
        onClick={() => {
          setIsSavePaymentsVisible(false);
        }}
      >
        Change card
      </div>
    </div>
  );
}
