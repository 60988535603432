import { deleteMessages, Widget } from 'react-chat-widget';
import React, { useEffect } from 'react';

function ChatWidget() {
  useEffect(() => {
    // Chat implementation to not interfere with the decline recovery
    const isHiddenClass = 'rcw-hide-sm';
    const chatWindow = document.querySelector('.rcw-widget-container');
    const chatButton = document.querySelector('.rcw-launcher');
    chatWindow.style.display = 'none';
    if (chatButton) {
      chatButton.addEventListener('click', () => {
        if (chatButton.classList.contains(isHiddenClass)) {
          chatWindow.style.display = 'none';
          deleteMessages(0);
        } else {
          chatWindow.style.display = 'flex';
        }
        chatWindow.style.width = chatButton.classList.contains(isHiddenClass) ? '0' : '90vw';
      });
    }
    // Close chat on click outside widget
    document.querySelector('#root').addEventListener('click', (event) => {
      const isInsideChat = chatWindow.contains(event.target);
      if (!isInsideChat) {
        const closeButton = document.querySelector('.rcw-launcher.rcw-hide-sm');
        if (closeButton) {
          closeButton.click();
        }
      }
    });
  }, []);

  return <Widget title="Support Chat" subtitle="how can we help you?" />;
}

export default ChatWidget;
