import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import style from '../styles/paymentItem.module.css';

import FormSubmit from './form-submit';

function PaymentItem({
  title,
  type,
  icon,
  url,
  checked = false,
  onRadioToggle,
  onFinish,
  onResetForm,
  isLoading,
  children,
}) {
  const renderLinkButton = () => (
    <a type="button" rel="noopener noreferrer" target="_blank" href={url} className="button checkout-btn is-fullwidth">
      {`${title}`}
    </a>
  );

  const renderCheckoutBtn = () => (
    <div>
      <FormSubmit
        onClick={onFinish}
        onCancel={onResetForm}
        isLoading={isLoading}
        id="checkout-button"
        disabled={isLoading}
      />
    </div>
  );
  return (
    <div className={style['payment-item']} onClick={() => onRadioToggle({ target: { id: title } })}>
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.type}>
            <label className="payment-cc radio">
              <input className="payment-radio" readOnly checked={checked} type="radio" name="payment" />
            </label>
            <div>
              {['CC'].includes(type) ? (
                <FontAwesomeIcon className={style['cc-icon']} icon={faCreditCard} />
              ) : (
                <img alt="payment" className={style['payment-type']} src={icon} />
              )}
            </div>
            <div className={style.title}>{title}</div>
          </div>
          <div className={style.cc_row}>
            {type === 'CC' ? <img alt="payment" className={style['cc-image']} src={icon} /> : ''}
          </div>
        </div>
        <div>{checked ? children : ''}</div>

        {checked ? (
          <div className={`controler ${style.actions}`}>
            {['CC'].includes(type) ? renderCheckoutBtn() : renderLinkButton()}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default PaymentItem;
