import React from 'react';
import '../styles/notification.css';

function Notification({ show, message }) {
  const render = () => (
    <div className="notification-custom notification animated fadeIn">
      <div className="notification-custom-description title">{message}</div>
      <div className="line-scale acs-loader">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
  return show ? render() : '';
}

export default Notification;
