/* eslint-disable jsx-a11y/no-noninteractive-tabindex, react/no-array-index-key */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

function Log({ log, showFlows, onFlowChange, onResetForm, onClearLogPressed, setShowFlows, consoleDiv }) {
  const [currentLogItem, setCurrentLogItem] = useState(0);

  const scroll = (newLogPos) => {
    if (consoleDiv.current) {
      const messages = [...document.getElementsByClassName('log-message')];
      const offset = messages.reduce((res, mes, index) => (newLogPos > index ? res + mes.offsetHeight : res), 0);
      consoleDiv.current.scroll(0, offset);
    }
  };

  const nextHighlight = (e) => {
    const DOWN = 40;
    const UP = 38;

    let newVal;

    const { keyCode } = e;
    if (keyCode === DOWN) {
      newVal = currentLogItem === log.length - 1 ? 0 : currentLogItem + 1;
    }
    if (keyCode === UP) {
      newVal = currentLogItem === 0 ? log.length - 1 : currentLogItem - 1;
    }
    if (newVal !== undefined) {
      setCurrentLogItem(newVal);
      e.preventDefault();
      scroll(newVal);
    }
  };

  const selectLogItem = (index) => {
    setCurrentLogItem(index);
  };
  const scrollDown = () => {
    if (consoleDiv.current) {
      consoleDiv.current.scroll(0, consoleDiv.current.scrollHeight);
    }
  };
  const onFlowChangeClick = () => {
    setCurrentLogItem(0);
    onFlowChange();
  };
  const renderScrollNotif = () => {
    let isOverFlowing = false;

    if (consoleDiv.current && log.length !== 0) {
      const { offsetHeight, scrollHeight } = consoleDiv.current;
      isOverFlowing = scrollHeight !== offsetHeight;
    }
    return isOverFlowing ? (
      <div className="scroll-notif tooltip is-tooltip-left" data-tooltip="Scroll down for viewing the full log">
        <div onClick={scrollDown} className="animated infinite pulse">
          <FontAwesomeIcon icon={faArrowCircleDown} />
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <>
      <div className="log-header">
        <div className="log-title">Log</div>
        <div className="log-buttons">
          <button type="button" onClick={onFlowChangeClick} className="button is-small util-button">
            Refill Form
          </button>
          <button type="button" onClick={onResetForm} className="button is-small util-button">
            Clear Form
          </button>
          <button id="clear-log" type="button" onClick={onClearLogPressed} className="button is-small util-button">
            Clear Log
          </button>
          <button
            type="button"
            onClick={() => {
              setShowFlows(!showFlows);
            }}
            className="button is-small util-button"
          >
            {showFlows ? 'Expend Log' : 'Minimize Log'}
          </button>
        </div>
      </div>
      <div className="console-container" ref={consoleDiv} tabIndex="0" onKeyDown={nextHighlight}>
        {renderScrollNotif()}
        {log.map(({ text, type }, index) => (
          <div
            onClick={() => {
              selectLogItem(index);
            }}
            className={`log-message animated fadeIn ${type}-console ${
              index === currentLogItem ? 'active-log-item' : ''
            }`}
            key={index}
          >
            {text}
          </div>
        ))}
      </div>
    </>
  );
}

export default Log;
