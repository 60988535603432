import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import '../styles/thankyou.css';
import FlowMessages from './flow-messages';

function ThankYouPage({ show = false, onBackPressed, steps, showFlowMessage }) {
  const render = () => (
    <div>
      <div className="complete-container">
        <div className="title complete-title">Thank you!</div>
        <div className="subtitle">Your order was completed successfully</div>
        <div id="continue-shopping" className="button back-to-shop-button" onClick={onBackPressed}>
          <FontAwesomeIcon icon={faShoppingCart} />
          Continue Shopping
        </div>
        {showFlowMessage ? <FlowMessages steps={steps} isAnimation={false} /> : ''}
      </div>
    </div>
  );
  return show ? render() : '';
}

export default ThankYouPage;
