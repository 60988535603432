import React from 'react';
import '../styles/challenge.css';

function Challenge({ show = false, isModal = false, challengeContainer, onModalClose, className }) {
  const onClose = () => {
    onModalClose();
  };

  const renderDefault = () => (
    <span id="challenge">
      <div className="bank-page-title">Bank Secured Page</div>
      <div className="bank-page-sub-title"> *Bank provided UI according to EMVco 3DS specification</div>
      <div ref={challengeContainer} className={`challenge-container ${className}`} id="challenge-container" />
    </span>
  );
  const RenderModal = () => (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content challenge-modal">
        <button type="button" onClick={onClose} className="modal-close is-large" aria-label="close" />
        {renderDefault()}
      </div>
    </div>
  );
  const render = () => {
    if (show) {
      return isModal ? RenderModal() : renderDefault();
    }
    return '';
  };
  return render();
}

export default Challenge;
