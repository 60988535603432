import { SERVER_BASE_URL } from '../settings';

export default {
  send: ({ method = 'post', path, body }) => {
    // For demo site usage
    const params = new URLSearchParams(window.location.search);
    const headers = {
      authorization: params.get('authorization'),
      siteid: params.get('siteid'),
      api_version: params.get('api_version'),
      acquirer_data: params.get('acquirer_data'),
      additional_information: params.get('additional_information'),
      additional_identifiers: params.get('additional_identifiers'),
      'x-forter-sub-merchant-siteid': params.get('x-forter-sub-merchant-siteid'),
    };
    // For demo site usage
    return fetch(`${SERVER_BASE_URL}/${path}`, {
      method,
      body,
      headers,
    });
  },
};
