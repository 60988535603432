/* eslint-disable camelcase */
import React, { useState } from 'react';
import '../styles/data-entry.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShieldAlt, faPen, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/data-entry.module.css';
import FormField from './form-field';
import { COUNTRIES, CURRENCY, FLAGS } from '../helpers/enums';
import PaymentItem from './payment-item';
import CreditCardDetails from './creditCardDetails';
import AchDetails from './achDetails';

function DataEntryPage({
  onResetForm,
  isLoading,
  formFields,
  onCreditCardChanged,
  onFormInputChange,
  onFinish,
  paymentTypes,
  errorMessage,
  inputErrors,
  isBillingInfoDisabled,
  onPaymentChange,
  currency,
  onSelectCurrency,
  validateInputs,
  ccIcon,
}) {
  const { email, firstName, lastName, address, zipCode, country } = formFields;
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const onSavedPaymentToggle = () => {};
  const onCountryChange = (e) => {
    setIsDropdownActive(false);
    const option = e.target;
    onFormInputChange(option);
  };

  const [isCurrencyActive, setIsCurrencyActive] = useState(false);
  const onCurrencyChange = (e) => {
    setIsCurrencyActive(false);
    const option = e.target;
    onSelectCurrency(option);
  };

  const onCountriesClick = (event) => {
    event.stopPropagation();
    setIsDropdownActive(!isDropdownActive);
  };

  const onCurrencyClick = (event) => {
    event.stopPropagation();
    setIsCurrencyActive(!isCurrencyActive);
  };

  window.addEventListener('click', () => {
    if (isDropdownActive) {
      setIsDropdownActive(false);
    }
    if (isCurrencyActive) {
      setIsCurrencyActive(false);
    }
  });

  const renderCountries = () => (
    <div className="countries">
      <div>
        <label className="form-field-title">Country</label>
      </div>
      <div className={`dropdown ${isDropdownActive ? 'is-active' : ''}`}>
        <div className="dropdown-trigger">
          <button
            className="button has-text-left drop-down"
            type="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={onCountriesClick}
          >
            <span>{COUNTRIES[country].name}</span>
            <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon edit-icon" />
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {Object.keys(COUNTRIES).map((code) => (
              <div
                name={code}
                className={`dropdown-item has-text-left ${country === code ? 'active' : ''}`}
                key={code}
                value={code}
                id="country"
                onClick={onCountryChange}
              >
                <img alt="flag" className="dropdown-image" src={FLAGS[code]} />
                {COUNTRIES[code].name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrency = () => (
    <div className="currency">
      <div>
        <label className="form-field-title">Currency</label>
      </div>
      <div className={`dropdown ${isCurrencyActive ? 'is-active' : ''}`}>
        <div className="dropdown-trigger">
          <button
            id="currencyContainer"
            className="button has-text-left drop-down"
            type="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={onCurrencyClick}
          >
            <span>{currency}</span>
            <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon edit-icon" />
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {Object.keys(CURRENCY).map((name) => (
              <div
                name={name}
                className={`dropdown-item has-text-left ${currency === name ? 'active' : ''}`}
                key={name}
                value={name}
                id="currency"
                onClick={onCurrencyChange}
              >
                {CURRENCY[name].name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderPaymentInfo = () => {
    const children = {
      CC: (
        <CreditCardDetails
          setIsSavedPayments={onSavedPaymentToggle}
          formFields={formFields}
          onFormInputChange={onFormInputChange}
          onCreditCardChanged={onCreditCardChanged}
          inputErrors={inputErrors}
          validateInputs={validateInputs}
          ccIcon={ccIcon}
        />
      ),
      ACH: <AchDetails />,
    };

    const payments = paymentTypes.map((payment) => (
      <PaymentItem
        key={payment.title}
        {...payment}
        onRadioToggle={onPaymentChange}
        onResetForm={onResetForm}
        isLoading={isLoading}
        onFinish={onFinish}
      >
        {children[payment.type]}
      </PaymentItem>
    ));

    return <div className={styles.payments}>{payments}</div>;
  };

  const render = () => (
    <div>
      <div className="checkout">
        <div className="iframe-wrap">
          <div id="iframecontainer" />
        </div>
        <div className="input-container">
          <div>
            {errorMessage && (
              <div className="notification error-message">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {errorMessage}
              </div>
            )}
          </div>
          <div className="shipping-info">
            <div className="shipping-info-title">
              {isBillingInfoDisabled ? <FontAwesomeIcon icon={faPen} className="text-btn edit-icon" /> : ''}
              <div>Billing Info</div>
            </div>
            <div className="secure-lock">
              <FontAwesomeIcon className="lock-icon" icon={faShieldAlt} />
              <span>Secure Checkout</span>
            </div>
            <div className="email">
              <FormField
                errors={inputErrors}
                disabled={isBillingInfoDisabled}
                value={email}
                onChange={onFormInputChange}
                id="email"
                placeholder="Email"
                onBlur={validateInputs}
              >
                Email
              </FormField>
            </div>
            <div className="first-name">
              <FormField
                errors={inputErrors}
                disabled={isBillingInfoDisabled}
                value={firstName}
                onChange={onFormInputChange}
                id="firstName"
                placeholder="First name"
                onBlur={validateInputs}
              >
                First name
              </FormField>
            </div>
            <div className="last-name">
              <FormField
                errors={inputErrors}
                disabled={isBillingInfoDisabled}
                value={lastName}
                onChange={onFormInputChange}
                id="lastName"
                placeholder="Last name"
                onBlur={validateInputs}
              >
                Last name
              </FormField>
            </div>
            <div className="address">
              <FormField
                disabled={isBillingInfoDisabled}
                value={address}
                onChange={onFormInputChange}
                id="address"
                placeholder="Address"
              >
                Address
              </FormField>
            </div>
            <div className="zip-code">
              <FormField
                errors={inputErrors}
                disabled={isBillingInfoDisabled}
                value={zipCode}
                onChange={onFormInputChange}
                id="zipCode"
                placeholder="Zip"
                onBlur={validateInputs}
              >
                Zip code
              </FormField>
            </div>
            {renderCountries()}
            {renderCurrency()}
          </div>
          <div className="payment-info">
            <div className="payment-info-title">
              <div>Payment</div>
            </div>
            {renderPaymentInfo()}
          </div>
        </div>
      </div>
    </div>
  );

  return render();
}

export default DataEntryPage;
