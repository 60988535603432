import React from 'react';
import '../styles/form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import * as Icons from '@fortawesome/free-brands-svg-icons';
import style from '../styles/paymentItem.module.css';

function FormField({ children, ccIcon, type, onChange, errors = {}, ...inputProps }) {
  const { id } = inputProps;
  const errorMessage = errors[id];
  const errorTextClass = errorMessage ? 'error-text tooltip' : '';
  const errorInputClass = errorMessage ? 'input-error' : '';

  const change = (e) => {
    if (onChange) {
      onChange(e.target);
    }
  };

  const renderIcon = () => (
    <span className="icon is-small is-right">
      <FontAwesomeIcon className={style['cc-type-icon']} icon={Icons[ccIcon]} />
    </span>
  );

  return (
    <div className="field">
      <label className={`form-field-title ${errorTextClass}`} data-tooltip={errorMessage}>
        {children} {errorTextClass ? <FontAwesomeIcon icon={faExclamationCircle} /> : ''}
      </label>
      <p className={`control ${ccIcon ? 'has-icons-right' : ''}`}>
        <input
          type={type || 'text'}
          className={`input is-small ${errorInputClass} form-input`}
          onChange={change}
          {...inputProps}
        />
        {ccIcon ? renderIcon() : ''}
      </p>
    </div>
  );
}

export default FormField;
