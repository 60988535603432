/* eslint-disable react/button-has-type,jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid,consistent-return */
import React, { useEffect, useRef, useState } from 'react';
import '../styles/console.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faAlignJustify, faCog, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { TABS, CHALLENGE_POSITIONS, DECLINE_RECOVERY_DISPLAY, PAYMENTS_ENV } from '../helpers/enums';
import { FLOW_TYPES, FLOW_DATA, SECTION_TYPES } from '../helpers/flows';
import products from '../helpers/products';
import Log from './log';
import FlowButtons from './flow-buttons';
import { getHas3ds } from '../helpers/common';

const TAB_ICONS = {
  faAlignJustify,
  faCog,
  faLightbulb,
};

const to = '->';

function Console({
  log,
  onResetForm,
  onClearLogPressed,
  onChallengePositionChanged,
  onDeclineRecoveryChange,
  onPaymentsEnvChange,
  onCorrelationIdToggle,
  hideSideMenu,
  threeDsSettings,
  product,
  onFlowChange,
  onProductChange,
  onIsInspectEnabledChanged,
  history,
  section,
  onSectionSelected,
  urlConvert,
}) {
  const consoleDiv = useRef(null);
  let hasTooltip = '';
  const [activeTab, setActiveTab] = useState('FLOWS');
  const [showFlows, setShowFlows] = useState(true);

  useEffect(() => {
    if (history.location.pathname === '/') {
      const flowName = FLOW_DATA[threeDsSettings.flow].title;
      history.push({
        pathname: `/${urlConvert(section.type)}/${urlConvert(flowName)}`,
        search: history.location.search,
      });
    }
  }, []);

  if (window.innerWidth >= 992) {
    hasTooltip = 'tooltip';
  }

  const renderProduct = (type) => {
    const { name } = products[type];

    return (
      <option key={type} value={type}>
        {name}
      </option>
    );
  };

  const renderTab = (type) => {
    const { title, icon } = TABS[type];

    return (
      <li key={type} className={`${type === activeTab ? 'is-active' : ''}`} onClick={() => setActiveTab(type)}>
        <a>
          <FontAwesomeIcon icon={TAB_ICONS[icon]} />
          <span className="tab-text">{title}</span>
        </a>
      </li>
    );
  };

  const getSections = () => {
    const { PAYMENTS, NO_FORTER, DECLINE_RECOVERY, DECLINE_RECOVERY_DEMO } = SECTION_TYPES;
    const initialFlows = [PAYMENTS, NO_FORTER, DECLINE_RECOVERY, DECLINE_RECOVERY_DEMO];
    const has3ds = getHas3ds();
    const whiteListed = FLOW_TYPES.filter(([value]) => initialFlows.includes(value));
    return has3ds ? FLOW_TYPES : whiteListed;
  };
  const renderSelect = () => {
    if (!showFlows) return <div />;

    const flowTypes = getSections();
    return (
      <div>
        <div className="sectionPicker select is-small">
          <select id="flow-picker" value={section.index} onChange={onSectionSelected}>
            {flowTypes.map(([, title], index) => (
              <option key={title} value={index}>
                {title}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const renderFlows = () => {
    if (activeTab === 'FLOWS' || activeTab === 'ERROR_FLOWS') {
      return (
        <div className={`log-container ${showFlows ? '' : 'compress'}`}>
          {renderSelect()}
          <FlowButtons
            showFlows={showFlows}
            threeDsSettings={threeDsSettings}
            onFlowChange={onFlowChange}
            flowType={activeTab}
            section={section}
          />
          <Log
            log={log}
            showFlows={showFlows}
            onFlowChange={onFlowChange}
            onResetForm={onResetForm}
            onClearLogPressed={onClearLogPressed}
            setShowFlows={setShowFlows}
            consoleDiv={consoleDiv}
          />
        </div>
      );
    }
  };

  const renderChallengeOptions = (type) => {
    const { title, value } = CHALLENGE_POSITIONS[type];
    const { challengePosition } = threeDsSettings;
    const isChecked1 = value === challengePosition;

    return (
      <label key={type} className="radio custom-radio">
        <input type="radio" name="challange_mode" value={value} defaultChecked={isChecked1} /> {title}
      </label>
    );
  };

  const renderDeclineRecoveryDisplay = (type) => {
    const value = DECLINE_RECOVERY_DISPLAY[type];
    const { declineRecoveryDisplay } = threeDsSettings;
    const isChecked2 = value === declineRecoveryDisplay;

    return value === 'chat' && section.type !== 'DECLINE_RECOVERY_DEMO' ? (
      ''
    ) : (
      <label key={type} className="radio custom-radio">
        <input type="radio" name="decline_recovery" value={value} defaultChecked={isChecked2} /> {value}
      </label>
    );
  };

  const renderPaymentsEnv = (type) => {
    const value = PAYMENTS_ENV[type];
    const { paymentsEnv } = threeDsSettings;
    const isChecked = value === paymentsEnv;

    return (
      <label key={type} className="radio custom-radio">
        <input type="radio" name="payments_env" value={value} defaultChecked={isChecked} /> {value}
      </label>
    );
  };

  const renderHints = () => {
    if (activeTab === 'HINTS') {
      return (
        <div className="hints">
          <div className="hints-section">
            1. Enter your 6 digit number - <span>123456</span>
          </div>
          <div className="hints-section">
            2. What was the name of your first pet? - <span>Thomason</span>
          </div>
          <div className="hints-section">
            3. Which cities have you lived in? - <span>St Louis / Dallas</span>
          </div>
          <div className="hints-section">
            4. What was the last name of your favorite teacher? - <span>Smith</span>
          </div>
          <div className="hints-section">
            5. Payment Recommendation Month Expiry Codes
            <span>
              <div>08 adyen,worldpay</div>
              <div>09 worldpay,stripe </div>
              <div>10 stripe,adyen</div>
              <div>11 vantiv,chasepaymentech</div>
              <div>12 chasepaymentech</div>
            </span>
          </div>
          <div className="hints-section">
            6. OTP success code
            <span>
              <div>111111</div>
            </span>
          </div>
          <div className="hints-section">
            7. access flow from url https://3ds-demo-store.forter.com/?flow=forter_approve
            <div className="flow-types">
              {Object.keys(FLOW_DATA).map((item) => (
                <div key={item}>- {item}</div>
              ))}
            </div>
          </div>
          <div className="hints-section">
            8. recommended processors toggled by expiry month
            <span>
              <div>08: adyen, worldpay (1st and 2nd priority)</div>
              <div>09: worldpay, stripe</div>
              <div>10: stripe, adyen</div>
              <div>11: vantiv, chasepaymentech</div>
              <div>12: chasepaymentech, vantiv</div>
            </span>
          </div>
          <div className="hints-section">
            9. quick flow navigation hotkeys without the side menu
            <span>
              <div>Alt + arrowLeft Or arrowRight</div>
            </span>
          </div>
          <div className="hints-section">
            10. Decline Recovery - Success Scenarios
            <span>
              <div>CVV: 111 {to} 112</div>
              <div>Address: 350 5th Avenue {to} 351 5th Avenue</div>
              <div>Credit card: 4111 1111 1111 1110 {to} 4111 1111 1111 1111</div>
              <div>First name: John {to} Joy</div>
              <div>Currency: USD {to} EUR</div>
              <div>Cart product: Wireless charger {to} Phone case</div>
            </span>
          </div>
        </div>
      );
    }
  };

  const renderThreeDsSettings = () => {
    if (activeTab === 'THREE_DS') {
      const { enableCorrelationId, disableProductSelection, isInspectEnabled } = threeDsSettings;

      return (
        <div className="">
          <div className="three-ds-settings-section">
            <div>Challenge Position</div>
            <div className="control challengeSelector" onChange={onChallengePositionChanged}>
              {Object.keys(CHALLENGE_POSITIONS).map(renderChallengeOptions)}
            </div>
          </div>
          <div className="three-ds-settings-section">
            <div>Creditcard Correlation Id</div>
            <label className="checkbox correlation-id-toggle">
              <input type="checkbox" checked={enableCorrelationId} onChange={onCorrelationIdToggle} /> Use on order
            </label>
          </div>
          <div className="three-ds-settings-section">
            <div>Slow Motion Mode</div>
            <label className="checkbox correlation-id-toggle">
              <input type="checkbox" checked={isInspectEnabled} onChange={onIsInspectEnabledChanged} /> Toggle slow
              motion mode
            </label>
          </div>
          <div className="three-ds-settings-section">
            <div>Checkout Product</div>
            <div className="select products-select">
              <select disabled={disableProductSelection} defaultValue={product} onChange={onProductChange}>
                {Object.keys(products).map(renderProduct)}
              </select>
            </div>
          </div>
          <div className="three-ds-settings-section">
            <div>Decline Recovery Display</div>
            <div className="control challengeSelector" onChange={onDeclineRecoveryChange}>
              {Object.keys(DECLINE_RECOVERY_DISPLAY).map(renderDeclineRecoveryDisplay)}
            </div>
          </div>
          <div className="three-ds-settings-section">
            <div>Payments ENV</div>
            <div className="control challengeSelector" onChange={onPaymentsEnvChange}>
              {Object.keys(PAYMENTS_ENV).map(renderPaymentsEnv)}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div id="drawer" className="console-quick-view">
      <div className="toolbar">
        <div className="tabs is-toggle custom-tabs">
          <ul>{Object.keys(TABS).map(renderTab)}</ul>
        </div>
        <div className="toolbar-buttons">
          <div
            className={`console-btn collapse-btn ${hasTooltip} is-tooltip-left`}
            data-tooltip="Collapse Log"
            onClick={hideSideMenu}
          >
            <FontAwesomeIcon icon={faMinus} />
          </div>
        </div>
      </div>
      {renderFlows()}
      {renderThreeDsSettings()}
      {renderHints()}
    </div>
  );
}

export default withRouter(Console);
