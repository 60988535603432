import React from 'react';
import '../styles/form.css';

function FormSubmit({ onCancel, isLoading, children, ...buttonProps }) {
  const loading = isLoading ? 'is-loading' : '';

  return (
    <button type="button" className={`button checkout-btn is-fullwidth ${loading}`} {...buttonProps}>
      Pay
    </button>
  );
}

export default FormSubmit;
