import { COUNTRIES, LOG_TYPES } from './enums';
import { FLOW_DATA, SECTION_TYPES } from './flows';

const FORTER_API_BASE = 'https://api.forter-secure.com/v3';
/**
 * for demo only we mock a failed response from the gate
 * @param {*} gateway
 */
const setResponseStatus = (gateway) => {
  if (gateway.response.Body) {
    gateway.response.Body.resultCode = 'Refused';
    gateway.response.Body.authCode = 'Refused';
    return;
  }
  if (gateway.response.status) {
    gateway.response.status = 'Refused';
    gateway.response.failure_code = 'Refused';
  }
};
export default class appActions {
  static threeDsInit(pushLogEntry, payload) {
    pushLogEntry('(user action) user entered credit card', LOG_TYPES.USER_ACTION);
    pushLogEntry('(browser) POST https://rocket-electronics.com/init', LOG_TYPES.BROWSER);
    pushLogEntry(JSON.stringify(payload, null, 4), LOG_TYPES.PAYLOAD);
    pushLogEntry('(browser) ...waiting for response...', LOG_TYPES.BROWSER);
    // pushLogEntry('(merchant server) https://rocket-electronics.com/init request received', LOG_TYPES.MERCHANT);
    pushLogEntry(`(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/3ds/init`, LOG_TYPES.MERCHANT);
  }

  static browserResponseReceived(pushLogEntry, response) {
    pushLogEntry('(browser) response received', LOG_TYPES.BROWSER);
    pushLogEntry(JSON.stringify(response, null, 4), LOG_TYPES.PAYLOAD);
  }

  static merchantServerResponseReceivedPassingToBrowser(pushLogEntry, response) {
    pushLogEntry(
      '(merchant server) response received from Forter, passing the response back to the browser',
      LOG_TYPES.MERCHANT,
    );
    pushLogEntry(JSON.stringify(response, null, 4), LOG_TYPES.FORTER);
  }

  static browserCallingFtrInit3DS(pushLogEntry) {
    pushLogEntry('(browser) calling ftr__.init3DS(response, [callback])', LOG_TYPES.BROWSER);
    pushLogEntry('(background) sending data to the issuer...', LOG_TYPES.BACKGROUND_ACTION);
    // pushLogEntry(JSON.stringify(response, null, 4), LOG_TYPES.FORTER);
    pushLogEntry(
      '(browser) Storing the threeDSServerTransID + correlationId in a variable, it will be used in the order validation API call',
      LOG_TYPES.BROWSER,
    );
  }

  static errorOccurred(pushLogEntry, error) {
    pushLogEntry(`an error occurred: ${error}`, LOG_TYPES.ERROR);
  }

  static issuerDataCollectionCompleted(pushLogEntry) {
    pushLogEntry('(background) issuer data collection has completed', LOG_TYPES.BACKGROUND_ACTION);
    pushLogEntry('(browser) ftr__.init3DS Callback called', LOG_TYPES.BROWSER);
  }

  static payApiCall(pushLogEntry, payment) {
    pushLogEntry('(user action) clicked checkout button', LOG_TYPES.USER_ACTION);
    pushLogEntry('(browser) POST https://rocket-electronics.com/pay', LOG_TYPES.BROWSER);
    pushLogEntry(JSON.stringify(payment, null, 4), LOG_TYPES.PAYLOAD);
    pushLogEntry('(browser) ...waiting for response...', LOG_TYPES.BROWSER);
  }

  static payApiCallAfterOtp(pushLogEntry, payment) {
    pushLogEntry('(browser) OTP challenge was successful', LOG_TYPES.USER_ACTION);
    pushLogEntry('(browser) POST https://rocket-electronics.com/pay', LOG_TYPES.BROWSER);
    pushLogEntry(JSON.stringify(payment, null, 4), LOG_TYPES.PAYLOAD);
    pushLogEntry('(browser) ...waiting for response...', LOG_TYPES.BROWSER);
  }

  static adaptiveAuthApiCallAfterOtp(pushLogEntry, res) {
    const { preAuth } = res;

    // find the first priority gateway
    pushLogEntry('(merchant server) https://rocket-electronics.com/pay request received', LOG_TYPES.MERCHANT);
    pushLogEntry(
      `(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/orders/[orderId] (post-auth)`,
      LOG_TYPES.MERCHANT,
    );
    pushLogEntry(JSON.stringify(preAuth.payload, null, 4), LOG_TYPES.PAYLOAD);
    pushLogEntry('(merchant server) response received from Forter', LOG_TYPES.MERCHANT);
    pushLogEntry(JSON.stringify(preAuth.response, null, 4), LOG_TYPES.FORTER);
  }

  static adaptiveAuthApiCall(pushLogEntry, res, shouldRetry) {
    const { preAuth, postAuth, processors = {} } = res;

    // find the first priority gateway
    const firstPriorityGatway = Object.values(processors).find((item) => item.recomendation.priority === 1) || {};
    const fallbackGateway = Object.values(processors).find((item) => item.recomendation.priority === 2);
    pushLogEntry('(merchant server) https://rocket-electronics.com/pay request received', LOG_TYPES.MERCHANT);
    pushLogEntry(
      `(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/orders/[orderId] (pre-auth)`,
      LOG_TYPES.MERCHANT,
    );
    pushLogEntry(JSON.stringify(preAuth.payload, null, 4), LOG_TYPES.PAYLOAD);
    pushLogEntry('(merchant server) response received from Forter', LOG_TYPES.MERCHANT);
    pushLogEntry(JSON.stringify(preAuth.response, null, 4), LOG_TYPES.FORTER);
    if (firstPriorityGatway.recomendation) {
      pushLogEntry(
        `(merchant server) calling ${firstPriorityGatway.recomendation.processorRoute} for authorization`,
        LOG_TYPES.MERCHANT,
      );
    }

    if (firstPriorityGatway.request) {
      pushLogEntry(JSON.stringify(firstPriorityGatway.request, null, 4), LOG_TYPES.PAYLOAD);
    }
    if (shouldRetry === false && firstPriorityGatway.recomendation) {
      pushLogEntry(
        `(merchant server) ...waiting for ${firstPriorityGatway.recomendation.processorRoute}...`,
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(
        `(merchant server) response received from ${firstPriorityGatway.recomendation.processorRoute}...`,
        LOG_TYPES.MERCHANT,
      );
    }
    if (shouldRetry) {
      pushLogEntry(
        `(merchant server) ...waiting for ${firstPriorityGatway.recomendation.processorRoute}...`,
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(
        `(merchant server) response received from ${firstPriorityGatway.recomendation.processorRoute} (do not honor)`,
        LOG_TYPES.MERCHANT,
      );

      setResponseStatus(firstPriorityGatway);

      pushLogEntry(JSON.stringify(firstPriorityGatway.response, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry(
        `(merchant server) retrying with ${fallbackGateway.recomendation.processorRoute}`,
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(
        `(merchant server) calling ${fallbackGateway.recomendation.processorRoute} for authorization`,
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(JSON.stringify(fallbackGateway.request, null, 4), LOG_TYPES.PAYLOAD);
      pushLogEntry(
        `(merchant server) ...waiting for ${fallbackGateway.recomendation.processorRoute}...`,
        LOG_TYPES.MERCHANT,
      );
      pushLogEntry(
        `(merchant server) response received from ${fallbackGateway.recomendation.processorRoute}...`,
        LOG_TYPES.MERCHANT,
      );
      if (fallbackGateway.response) {
        pushLogEntry(JSON.stringify(fallbackGateway.response, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry(
          `(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/orders/[orderId] (post-auth)`,
          LOG_TYPES.MERCHANT,
        );
        pushLogEntry(JSON.stringify(postAuth.payload, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry('(merchant server) response received from Forter', LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify(postAuth.response, null, 4), LOG_TYPES.FORTER);
      }
    } else {
      if (firstPriorityGatway.processorRoute) {
        pushLogEntry(
          `(merchant server) response received from ${firstPriorityGatway.processorRoute} Authorised`,
          LOG_TYPES.MERCHANT,
        );
      }
      if (firstPriorityGatway.response) {
        pushLogEntry(JSON.stringify(firstPriorityGatway.response, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry(
          `(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/orders/[orderId] (PostAuth)`,
          LOG_TYPES.MERCHANT,
        );
        pushLogEntry(JSON.stringify(postAuth.payload, null, 4), LOG_TYPES.PAYLOAD);
        pushLogEntry('(merchant server) response received from Forter', LOG_TYPES.MERCHANT);
        pushLogEntry(JSON.stringify(postAuth.response, null, 4), LOG_TYPES.FORTER);
      }
    }
  }

  static gatewayResReq(res, pushLogEntry, gateway, enablePostauth, dontHonor) {
    if (gateway.recomendation) {
      pushLogEntry(
        `(merchant server) calling ${gateway.recomendation.processorRoute} for authorization`,
        LOG_TYPES.MERCHANT,
      );
    }

    if (gateway.request) {
      pushLogEntry(JSON.stringify(gateway.request, null, 4), LOG_TYPES.PAYLOAD);
    }

    pushLogEntry(`(merchant server) ...waiting for ${gateway.recomendation.processorRoute}...`, LOG_TYPES.MERCHANT);
    pushLogEntry(
      `(merchant server) response received from ${gateway.recomendation.processorRoute} ${
        dontHonor ? '(do not honor)' : ''
      }`,
      LOG_TYPES.MERCHANT,
    );
    setResponseStatus(gateway);
    pushLogEntry(JSON.stringify(gateway.response, null, 4), LOG_TYPES.PAYLOAD);

    if (enablePostauth) {
      pushLogEntry(
        `(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/orders/[orderId] (post-auth)`,
        LOG_TYPES.MERCHANT,
      );

      pushLogEntry(JSON.stringify(res.postAuth.payload, null, 4), LOG_TYPES.PAYLOAD);
    }
  }

  static adaptiveAuthApiCallFailOnFirstGatePSD2(pushLogEntry, res) {
    const { preAuth, processors = {} } = res;

    const firstPriorityGatway = Object.values(processors).find((item) => item.recomendation.priority === 1) || {};
    const fallbackGateway = Object.values(processors).find((item) => item.recomendation.priority === 2);
    pushLogEntry('(merchant server) https://rocket-electronics.com/pay request received', LOG_TYPES.MERCHANT);
    pushLogEntry(
      `(merchant server) POST ${FORTER_API_BASE}/adaptive-auth/orders/[orderId] (pre-auth)`,
      LOG_TYPES.MERCHANT,
    );

    pushLogEntry(JSON.stringify(preAuth.payload, null, 4), LOG_TYPES.PAYLOAD);
    pushLogEntry('(merchant server) response received from Forter', LOG_TYPES.MERCHANT);
    pushLogEntry(JSON.stringify(preAuth.response, null, 4), LOG_TYPES.FORTER);

    this.gatewayResReq(res, pushLogEntry, firstPriorityGatway, false, true);
    this.gatewayResReq(res, pushLogEntry, fallbackGateway, true, true);
  }

  static callingFtrTriggerChallengeIfNeeded(pushLogEntry) {
    pushLogEntry(
      '(browser) Calling ftr__.triggerChallengeIfNeeded(response, [challengeContainer], [callback])',
      LOG_TYPES.BROWSER,
    );
    pushLogEntry('(browser) ...waiting for the user to submit the challenge...', LOG_TYPES.BROWSER);
  }

  static browserThreeDsChallengeStatus(pushLogEntry, error, wasChallengePerformed, transStatus, cres) {
    pushLogEntry(
      `(browser) Callback called (error:${error}, wasChallengePerformed:${wasChallengePerformed}, transStatus:${transStatus}, cres:${cres}`,
      LOG_TYPES.BROWSER,
    );
  }

  static verifyApiCall(pushLogEntry, cres, orderId) {
    pushLogEntry('(browser) POST https://rocket-electronics.com/verify', LOG_TYPES.BROWSER);
    pushLogEntry(JSON.stringify({ cres, orderId }, null, 4), LOG_TYPES.FORTER);
    pushLogEntry('(browser) ...waiting for response...', LOG_TYPES.BROWSER);
    pushLogEntry('(merchant server) https://rocket-electronics.com/verify request received', LOG_TYPES.MERCHANT);
    pushLogEntry(`(merchant server) POST ${FORTER_API_BASE}/'adaptive-auth/3ds/verify/[orderId]'`, LOG_TYPES.MERCHANT);
    pushLogEntry(JSON.stringify({ cres, orderId }, null, 4), LOG_TYPES.FORTER);
    pushLogEntry(
      'Verifying challenge with the 3DS server (issuer) and receiving the 3DS fields including the Authentication Value',
      LOG_TYPES.BACKGROUND_ACTION,
    );
  }

  static merchantServerResponseReceived(pushLogEntry, verificationResponse) {
    pushLogEntry('(merchant server) response received', LOG_TYPES.MERCHANT);
    pushLogEntry(JSON.stringify(verificationResponse, null, 4), LOG_TYPES.FORTER);
  }

  static browserChallengeCompleted(pushLogEntry) {
    pushLogEntry(
      '(browser)Challenge completed, using the CRES to retrieve the authentication value',
      LOG_TYPES.BROWSER,
    );
  }

  static threeDsAuthCompleted(pushLogEntry, authenticationValue, processors) {
    pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
    pushLogEntry('3DS AUTHENTICATION COMPLETED', LOG_TYPES.MERCHANT);
    pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
    if (processors) {
      const gateway = Object.values(processors).find((item) => item.recomendation.priority === 1);
      this.gatewayResReq({ processors }, pushLogEntry, gateway);
    } else {
      pushLogEntry(
        `(merchant server) Calling payment gateway with authenticationValue: ${authenticationValue}`,
        LOG_TYPES.MERCHANT,
      );
    }
  }

  static threeDsAuthCompletedWithDecline(pushLogEntry) {
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
    pushLogEntry('3DS AUTHENTICATION COMPLETED WITH A DECLINE', LOG_TYPES.ERROR);
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
  }

  static threeDsAuthCompletedWithNotReviewed(pushLogEntry) {
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
    pushLogEntry('3DS AUTHENTICATION COMPLETED WITH A FORTER NOT REVIEWED', LOG_TYPES.ERROR);
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
  }

  static noThreeDsTransactionDeclined(pushLogEntry) {
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
    pushLogEntry('TRANSACTION DECLINED (WITHOUT 3DS AUTHENTICATION)', LOG_TYPES.ERROR);
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
  }

  static noThreeDsTransactionNotReviewed(pushLogEntry) {
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
    pushLogEntry('TRANSACTION WAS NOT REVIEWED (WITHOUT 3DS AUTHENTICATION)', LOG_TYPES.ERROR);
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
  }

  static noThreeDsTransactionApproved(pushLogEntry, hasFlowsKit, country, threeDsSettings) {
    if (country === COUNTRIES.US.code) {
      pushLogEntry(
        `-----------------------------
TRANSACTION AUTHORIZED AND APPROVED (WITHOUT 3DS AUTHENTICATION) (Forter) 
-----------------------------`,
        LOG_TYPES.MERCHANT,
      );
    } else {
      pushLogEntry(
        `-----------------------------
TRANSACTION AUTHORIZED AND APPROVED (WITHOUT 3DS AUTHENTICATION) PSD2 TRA Exemption applied chargeback liability lies with the merchant (Forter) 
-----------------------------`,
        LOG_TYPES.MERCHANT,
      );
    }
    const { flow } = threeDsSettings;
    const flowSection = FLOW_DATA[flow].type;
    if (![SECTION_TYPES['3DS2.1_exemption'], SECTION_TYPES['3DS2.1_out_of_scope']].includes(flowSection)) {
      pushLogEntry('(merchant server) passing the response back to the browser', LOG_TYPES.MERCHANT);
    }
    if (!hasFlowsKit) {
      pushLogEntry('(browser) redirects the user to the order confirmation page', LOG_TYPES.BROWSER);
    }
  }

  static noThreeDsTransactionApprovedOTP(pushLogEntry) {
    pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
    pushLogEntry('TRANSACTION AUTHORIZED AND APPROVED (WITHOUT 3DS AUTHENTICATION)', LOG_TYPES.MERCHANT);
    pushLogEntry('-----------------------------', LOG_TYPES.MERCHANT);
    pushLogEntry('(merchant server) passing the response back to the browser', LOG_TYPES.MERCHANT);
    pushLogEntry('(browser) redirects the user to the order confirmation page', LOG_TYPES.BROWSER);
  }

  static callingGatewayWithExemption(pushLogEntry) {
    pushLogEntry('(merchant server) Calling payment gateway with exemption/exclusion request', LOG_TYPES.MERCHANT);
  }

  static bankRejectedAuth(pushLogEntry) {
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
    pushLogEntry('THE BANK HAS REJECTED THE AUTHENTICATION', LOG_TYPES.ERROR);
    pushLogEntry('-----------------------------', LOG_TYPES.ERROR);
  }

  static atlasSuccess(pushLogEntry) {
    pushLogEntry('(browser) redirects the user to the order confirmation page', LOG_TYPES.BROWSER);
  }

  static atlasFail(pushLogEntry) {
    pushLogEntry('(browser) keep user on checkout page', LOG_TYPES.BROWSER);
  }

  static initError(pushLogEntry, error) {
    pushLogEntry(`${error.message}`, LOG_TYPES.ERROR);
  }
}
