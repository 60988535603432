import { addResponseMessage } from 'react-chat-widget';

const options = {
  style: 'body {color: green; font-family: Comic Sans MS}',
  display: {
    type: '',
    container: '#iframecontainer',
  },
  disableFeatures: {
    retryCheckout: ['newExpiry', 'newBillingAddress'],
    directUserToAction: ['NEW_CARD'],
    useApm: true,
  },
};

const PAYMENT_TO_SITE = {
  paypal: 'https://www.paypal.com/',
  paypalCredit: 'https://www.paypal.com/us/webapps/mpp/paypal-credit',
  klarna: 'https://www.klarna.com/pay-now/',
  sofort: 'https://www.klarna.com/pay-now/',
  giropay: 'https://www.giropay.de/',
  directDebit: 'https://www.directdebit.co.uk/Pages/Home.aspx',
  amazonPay: 'https://pay.amazon.com/using-amazon-pay',
  ideal: 'https://www.ideal.nl/en/',
  affirm: 'https://www.affirm.com/',
  ach: 'https://www.ach-payments.com/',
  swish: 'https://www.swish.nu/',
};

const ACTION_TO_FOCUS_INPUT = [
  {
    actions: ['CHANGE_CVV'],
    inputType: 'cvv',
  },
  {
    actions: ['CHANGE_ADDRESS', 'CHANGE_ADDRESS_TO_AVS'],
    inputType: 'address',
  },
  {
    actions: ['CHANGE_EXPIRATION'],
    inputType: 'expiry',
  },
  {
    actions: ['CHANGE_NAME'],
    inputType: 'firstName',
  },
  {
    actions: [
      'CHANGE_PROCESSOR',
      'CHANGE_CARD_SCHEME',
      'CHANGE_ISSUER',
      'CHANGE_CC',
      'CHANGE_CARD_TYPE_FROM_PREPAID',
      'CHANGE_CARD_TYPE_TO_CREDIT',
      'CHANGE_CARD_TYPE_TO_DEBIT',
      'CHANGE_CARD_TYPE_TO_PREPAID',
      'RETYPE_CC',
    ],
    inputType: 'cc',
  },
  {
    actions: ['CHANGE_CURRENCY'],
    inputType: 'currencyContainer',
  },
  {
    actions: ['CHANGE_AMOUNT'],
    inputType: 'remove',
  },
];

/**
 * highlight the invald input element
 * @param {String} id
 */
const focusOnError = (id) => {
  const element = document.getElementById(id);
  element.style.borderColor = '#f13f41';
  element.addEventListener('blur', () => {
    element.style.borderColor = '#10a19a47';
  });
  element.focus();
};

/**
 * open the chat widget
 */
const startChat = () => {
  document.getElementsByClassName('rcw-widget-container')[0].style.display = 'flex';
  document.getElementsByClassName('rcw-launcher')[0].click();
  addResponseMessage('Hello! How may I help you?');
};

/**
 * return the options object for checkoutTools.declineRecovery
 * @param {String} declineRecoveryDisplay the display type (container etc ...)
 */
export const getOptions = (declineRecoveryDisplay) => {
  options.display.type = declineRecoveryDisplay;
  return options;
};

/**
 * Focus on field
 * @param fieldId
 */
const focusOnField = (fieldId) => {
  const elem = document.getElementById(fieldId);
  if (elem) {
    elem.select();
    elem.focus();
  }
};

/**
 * return the callback configuration for  checkoutTools.declineRecovery
 */
export const getCallbackOptions = ({ setFormFields, formFields }) => ({
  directUserToAction: (action) => {
    const { inputType } = ACTION_TO_FOCUS_INPUT.find((item) => item.actions.includes(action));

    focusOnError(inputType);
  },
  openSupportTicket: () => {
    startChat();
  },
  startChat: () => {
    startChat();
  },
  retryCheckout: () => {
    setFormFields({ ...formFields, cvv: '112' });
    document.getElementById('checkout-button').click();
  },
  useAPM: (paymentType) => {
    window.open(PAYMENT_TO_SITE[paymentType], '_blank');
  },
  changeCardTypeToDebit: () => {
    // nothing to do
  },
  changeCardTypeToCredit: () => {
    // nothing to do
  },
  changeCardTypeFromPrepaid: () => {
    // nothing to do
  },
  changeCardTypeToPrepaid: () => {
    // nothing to do
  },
  changeIssuer: () => {
    // nothing to do
  },
  changeCardScheme: () => {
    // nothing to do
  },
  changeCurrency: () => {
    focusOnField('currencyContainer');
  },
  changeCVV: () => {
    focusOnField('cvv');
  },
  changeExpiration: () => {
    focusOnField('expiry');
  },
  retypeCC: () => {
    focusOnField('cc');
  },
  changeCC: () => {
    focusOnField('cc');
  },
  changeAddressToAVS: () => {
    focusOnField('address');
  },
  changeAddress: () => {
    focusOnField('address');
  },
  changeName: () => {
    focusOnField('firstName');
  },
});

export const declineRecoveryBot = {
  INSUFFICIENT_FUNDS: {
    recoveryMethods: [
      {
        type: 'retryCheckout',
        conversation: [
          'By any chance you got an SMS from the bank?',
          'Are you the one? Have you replied to the SMS with "1"?',
          'Cool! Now I will try again and it will hopefully work. Good to go?',
          'Retrying in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Worry not! Let's try something else, can you proceed with one of those firms?"],
      },
      {
        type: 'retryCheckout',
        conversation: [
          '{{actions.useAPM.ach}} {{actions.useAPM.affirm}}',
          'Have you called them? Have they approved your purchase?',
          'Cool! Now I will try again and it will hopefully work. Good to go?',
          'Retrying in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Ok, I promise it's the last thing I will ask you to do"],
      },
      {
        type: 'directUserToAction',
        code: 'CHANGE_ADDRESS',
        conversation: [
          'Have you, by any chance, provided your bank any address other than 351 5th Avenue, New York, NY',
          'Cool! You will be directed back to the checkout page in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Sorry I could not be more helpful. I'm just a robot, not a superhero"],
      },
      {
        type: 'end',
        conversation: ['Please contact our customer service at 877-700-1234'],
      },
    ],
    actions: {
      directUserToAction: {
        changeIssuer: {
          text: 'card',
          code: 'CHANGE_ISSUER',
        },
      },
      useAPM: {
        ach: {
          icon: 'assets/icons/ach.svg',
          tooltip: 'Pay with Ach',
        },
        affirm: {
          icon: 'assets/icons/affirm.svg',
          tooltip: 'Pay with Affirm',
        },
      },
    },
    title: 'Ohh... No! {{data.bank.logo}} Bank declined your payment! ',
    errorMessage: 'Due to insufficient funds',
    data: {
      bank: {
        name: {
          type: 'TEXT',
          value: 'JPMorgan Chase Bank',
        },
        logo: {
          type: 'IMAGE',
          value: 'assets/bank-logos/ING_BANK_NV.svg',
        },
        supportPhone: {
          type: 'PHONE',
          value: '1-800-9359935',
        },
      },
    },
    errorCode: 'INSUFFICIENT_FUNDS',
    compiledBody:
      'Due to insufficient funds. Try another card, preferably issued by a different brand. You can also call jPMorgan Chase Bank 1-800-9359935 to resolve and try again or try reducing your checkout amount.',
  },
  INVALID_ADDRESS: {
    recoveryMethods: [
      {
        type: 'directUserToAction',
        code: 'CHANGE_ADDRESS',
        conversation: [
          'Have you, by any chance, provided your bank any address other than 351 5th Avenue, New York, NY',
          'Cool! You will be directed back to the checkout page in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Worry not! Let's try something else"],
      },
      {
        type: 'retryCheckout',
        conversation: [
          "Would you please call your bank at {{data.bank.supportPhone}} and tell them that it's really you, trying to buy this charger?",
          'Have you called them? Have they approved your purchase?',
          'Cool! Now I will try again and it will hopefully work. Good to go?',
          'Retrying in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Ok, I promise it's the last thing I will ask you to do"],
      },
      {
        type: 'directUserToAction',
        code: 'CHANGE_CC',
        conversation: [
          'Do you happen to have another credit card?',
          'Cool! You will be directed back to the checkout page in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Sorry I could not be more helpful. I'm just a robot, not a superhero"],
      },
      {
        type: 'end',
        conversation: ['Please contact our customer service at 877-700-1234'],
      },
    ],
    actions: {},
    title: 'Ohh... No! {{data.bank.logo}} Bank  declined your payment! ',
    errorMessage: 'Due to insufficient funds',
    data: {
      bank: {
        name: {
          type: 'TEXT',
          value: 'JPMorgan Chase Bank',
        },
        logo: {
          type: 'IMAGE',
          value: 'assets/bank-logos/ING_BANK_NV.svg',
        },
        supportPhone: {
          type: 'PHONE',
          value: '1-800-9359935',
        },
      },
    },
    errorCode: 'INVALID_ADDRESS',
    compiledBody:
      'The address entered is incorrect. Update your address and try again. You can also try another card or call jPMorgan Chase Bank 1-800-9359935 to resolve and try again.',
  },
  DO_NOT_HONOR: {
    recoveryMethods: [
      {
        type: 'retryCheckout',
        conversation: [
          'By any chance you got an SMS from the bank?',
          'Are you the one? Have you replied to the SMS with "1"?',
          'Cool! Now I will try again and it will hopefully work. Good to go?',
          'Retrying in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Worry not! Let's try something else"],
      },
      {
        type: 'directUserToAction',
        code: 'CHANGE_CC',
        conversation: [
          'Do you happen to have another credit card?',
          'Cool! You will be directed back to the checkout page in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Sorry I could not be more helpful. I'm just a robot, not a superhero"],
      },
      {
        type: 'end',
        conversation: ['Please contact our customer service at 877-700-1234'],
      },
    ],
    actions: {},
    title: 'Ohh... No! {{data.bank.logo}} Bank declined your payment! ',
    errorMessage: 'Due to insufficient funds',
    data: {
      bank: {
        name: {
          type: 'TEXT',
          value: 'JPMorgan Chase Bank',
        },
        logo: {
          type: 'IMAGE',
          value: 'assets/bank-logos/ING_BANK_NV.svg',
        },
        supportPhone: {
          type: 'PHONE',
          value: '1-800-9359935',
        },
      },
    },
    errorCode: 'DO_NOT_HONOR',
    compiledBody:
      'The address entered is incorrect. Update your address and try again. You can also try another card or call jPMorgan Chase Bank 1-800-9359935 to resolve and try again.',
  },
  CONTACT_THE_BANK: {
    recoveryMethods: [
      {
        type: 'retryCheckout',
        conversation: [
          'By any chance you got an SMS from the bank?',
          'Are you the one? Have you replied to the SMS with "1"?',
          'Cool! Now I will try again and it will hopefully work. Good to go?',
          'Retrying in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Worry not! Let's try something else"],
      },
      {
        type: 'retryCheckout',
        conversation: [
          "Would you please call your bank at {{data.bank.supportPhone}} and tell them that it's really you, trying to buy this charger?",
          'Have you called them? Have they approved your purchase?',
          'Cool! Now I will try again and it will hopefully work. Good to go?',
          'Retrying in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Ok, I promise it's the last thing I will ask you to do"],
      },
      {
        type: 'directUserToAction',
        code: 'CHANGE_ADDRESS',
        conversation: [
          'Have you, by any chance, provided your bank any address other than 351 5th Avenue, New York, NY',
          'Cool! You will be directed back to the checkout page in 5.. 4.. 3.. 2..',
        ],
      },
      {
        type: 'separator',
        conversation: ["Sorry I could not be more helpful. I'm just a robot, not a superhero"],
      },
      {
        type: 'end',
        conversation: ['Please contact our customer service at 877-700-1234'],
      },
    ],
    actions: {},
    title: 'Ohh... No! {{data.bank.logo}} Bank  declined your payment! ',
    errorMessage: 'Due to insufficient funds',
    data: {
      bank: {
        name: {
          type: 'TEXT',
          value: 'JPMorgan Chase Bank',
        },
        logo: {
          type: 'IMAGE',
          value: 'assets/bank-logos/ING_BANK_NV.svg',
        },
        supportPhone: {
          type: 'PHONE',
          value: '1-800-9359935',
        },
      },
    },
    errorCode: 'CONTACT_THE_BANK',
    compiledBody:
      'Due to insufficient funds. Try another card, preferably issued by a different brand. You can also call jPMorgan Chase Bank 1-800-9359935 to resolve and try again or try reducing your checkout amount.',
  },
};
