// TODO - need to fix these linting problems
/* eslint-disable no-underscore-dangle,operator-linebreak,implicit-arrow-linebreak */
/* eslint-disable no-param-reassign,no-shadow */ /* eslint-disable jsx-a11y/no-static-element-interactions,no-use-before-define */
/* eslint-disable consistent-return,max-len */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react';
import 'react-chat-widget/lib/styles.css';
import cryptoJs from 'crypto-js';
import './AppStyles';
import { useParams, useHistory } from 'react-router-dom';
import {
  CHALLENGE_TYPES,
  NOTIFICATIONS,
  FORTER_DECISIONS,
  THREE_DS_VERSIONS,
  PAGES,
  DEMO_PHASES,
  COUNTRIES,
  PAYMENTS,
  DR_SUCCESS_FLOWS,
  CREDIT_CARD_MASKING,
} from './helpers/enums';
import { FLOW_DATA, FLOW_TYPES } from './helpers/flows';
import formHelper from './helpers/form-helper';
import products from './helpers/products';
import logActions from './helpers/appLogs';
import { validateCreditCard, validateCVV, validateExpiry, validateFormFields } from './helpers/validations';
import { payApi, verifyApi, initApi } from './helpers/apiCalls';
import {
  getDefaultFlow,
  getConsoleState,
  getDeclineRecoveryVersion,
  getDefaultProduct,
  isProductSelectionDisabled,
  getDefaultSection,
} from './helpers/common';
// Components
import ThankYouPage from './components/thank-you-page';
import Console from './components/console';
import ForterLogo from './components/forter-logo';
import DataEntryPage from './components/data-entry';
import OrderTable from './components/order-table';
import StoreHeader from './components/store-header';
import Notification from './components/notification';
import Challenge from './components/challenge';
import SideMenuButton from './components/side-menu-button';
import Loader from './components/loader';
import QuickFlowPicker from './components/quickFlowPicker';
import ChatWidget from './components/chat-widget';
import { useMainContext } from './components/stateProvider';
import { getCallbackOptions, getOptions, declineRecoveryBot } from './helpers/declineRecovery';

const { AJAX_REDIRECT, CHECKOUT, MODAL } = CHALLENGE_TYPES;
let isCheckoutButtonReady = false;
let pendingPayment;
let orderId;
let threeDSServerTransID;
let recommendedProcessors;

function App() {
  const DEFAULT_FLOW = 'decline_recovery_invalid_cvv';
  const [defaultSectionType, defaultSectionTitle, sectionIndex] = getDefaultSection();
  const [page, setPage] = useState('data-entry');
  const [log, setLog] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentState, setPaymentState] = useState({});
  const [challengeDisplay, setChallengeDisplay] = useState(AJAX_REDIRECT);
  const [declineRecoveryDisplay, setDeclineRecoveryDisplay] = useState('modal');
  const [paymentsEnv, setPaymentsEnv] = useState('Staging');
  const [showChallengeInCheckout, setShowChallengeInCheckout] = useState(false);
  const [showChallengeInModal, setShowChallengeInModal] = useState(false);
  const [showFlowAnimation, setShowFlowAnimation] = useState(false);
  const [isBillingInfoDisabled, setIsBillingInfoDisabled] = useState(false);
  const [correlationId, setCorrelationId] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isBackVisible, setisBackVisible] = useState(false);
  const [formFields, setFormFields] = useState(formHelper.getFormInitObj());
  const [product, setProduct] = useState(getDefaultProduct(DEFAULT_FLOW));
  const [removedFromCart, setRemovedFromCart] = useState(false);
  const initialAmount =
    parseInt(products[product].amount, 10) +
    parseInt(products[product].tax, 10) +
    parseInt(products[product].shipping, 10);
  const [amount, setAmount] = useState(initialAmount);
  const [showSideMenu, setShowSideMenu] = useState(getConsoleState());
  const [demoAnimationPhase, setDemoAnimationPhase] = useState(DEMO_PHASES.CHECKOUT);
  const [demoPhaseState, setDemoPhaseState] = useState({});
  const [inputErrors, setInputErrors] = useState({ id: null });
  const [creditCardType, setcreditCardType] = useState(CREDIT_CARD_MASKING.visa);
  const [quickFlowPicker, setQuickFlowPicker] = useState({
    show: false,
    flow: getDefaultFlow(DEFAULT_FLOW),
  });
  const [threeDsSettings, setThreeDsSettings] = useState({
    challengePosition: challengeDisplay,
    flow: getDefaultFlow(DEFAULT_FLOW),
    enableCorrelationId: false,
    isInspectEnabled: false,
    disableProductSelection: isProductSelectionDisabled(DEFAULT_FLOW),
    declineRecoveryDisplay,
    paymentsEnv,
  });
  const [section, setSection] = useState({ title: defaultSectionTitle, type: defaultSectionType, index: sectionIndex });
  const [currency, setCurrency] = useState('USD');

  const [selectedCountry, setSelectedCountry] = useState(formFields.country || COUNTRIES.US.code);
  const [paymentTypes, setPaymentTypes] = useState(PAYMENTS[COUNTRIES.LA.code]);
  const { setIsSavePaymentsVisible, setShowSavedPayments, setHideCVV } = useMainContext();
  /**
   * holds a ref to the element that will hold the challenge
   */
  const challengeContainer = useRef({});
  const history = useHistory();
  const { flowPath, sectionPath } = useParams();
  useEffect(() => {
    if (flowPath) {
      // change flow according to url
      const newFlow = Object.keys(FLOW_DATA).find((flow) => urlConvert(FLOW_DATA[flow].title) === flowPath);
      const index = FLOW_TYPES.findIndex((section) => urlConvert(section[0]) === sectionPath);
      // url doesn't exist
      if (!newFlow || index === -1) {
        const flowName = FLOW_DATA[threeDsSettings.flow].title;
        history.replace({
          pathname: `/${urlConvert(section.type)}/${urlConvert(flowName)}`,
          search: history.location.search,
        });
      } else {
        setThreeDsSettings({
          ...threeDsSettings,
          flow: newFlow,
        });
        const [type, title] = FLOW_TYPES[index];
        setSection({ type, title, index });
      }
      // only section in url
    } else if (!flowPath && sectionPath) {
      const index = FLOW_TYPES.findIndex((section) => urlConvert(section[0]) === sectionPath);
      if (index === -1) {
        history.replace({
          pathname: '/',
          search: history.location.search,
        });
      } else {
        const [type, title] = FLOW_TYPES[index];
        setSection({ type, title, index });
      }
      if (urlConvert(FLOW_DATA[threeDsSettings.flow].type) === sectionPath) {
        const flowName = FLOW_DATA[threeDsSettings.flow].title;
        history.replace({
          pathname: `/${urlConvert(sectionPath)}/${urlConvert(flowName)}`,
          search: history.location.search,
        });
      }
    }
  }, [history.location.pathname]);
  /**
   * run on first render
   */
  useEffect(() => {
    if (threeDsSettings.flow !== '') {
      // prevent init of the billing form if we have the error message displaying
      if (!errorMessage) {
        fillBillInfoForm();
      }
    }
  }, [threeDsSettings]);
  useEffect(() => {
    const newCardType = checkCardType();
    setcreditCardType(newCardType ? CREDIT_CARD_MASKING[newCardType] : '');
  }, [formFields.cc]);
  useEffect(() => {
    setInputErrors({ id: null });
  }, [threeDsSettings.flow]);
  useEffect(() => {
    const script = document.createElement('script');

    window.checkoutToolsConfiguration = { siteId: '954fce831acf' };
    if (threeDsSettings.paymentsEnv === 'Staging') {
      script.src = 'https://954fce831acf.cdn-f.checkouttools.com/assets/checkoutToolsSDK.full.1.1.0.js';
    } else if (threeDsSettings.paymentsEnv === 'Local') {
      script.src = 'http://localhost:4000/dist/assets/checkoutToolsSDK.js';
    } else {
      script.src = 'https://954fce831acf.cdn-f.checkouttools.com/assets/checkoutToolsSDK.full.1.1.0.js';
    }
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [paymentsEnv]);

  const getCurrentFlow = () => {
    const { flow = '' } = threeDsSettings;
    return FLOW_DATA[flow] ? FLOW_DATA[flow] : {};
  };

  const formatSteps = (steps) => {
    if (!recommendedProcessors) return steps;

    const mainProcessor = Object.values(recommendedProcessors).find((item) => item.recomendation.priority === 1)
      .recomendation.processorRoute;
    const fallbackProcessor = Object.values(recommendedProcessors).find((item) => item.recomendation.priority === 2)
      .recomendation.processorRoute;
    /* eslint-disable prettier/prettier */
    const formattedSteps = steps.map((current) =>
      current.replace('PROCESSOR1', mainProcessor).replace('PROCESSOR2', fallbackProcessor));
    /* eslint-enable prettier/prettier */
    return formattedSteps;
  };

  const getCurrentAnimationPhaseSteps = () => {
    const selectedFlow = getCurrentFlow();
    if (!selectedFlow.demoSteps) return [];

    const index = selectedFlow.demoSteps.findIndex((phase) => phase.type === demoAnimationPhase);
    const { steps } = selectedFlow.demoSteps[index];
    return formatSteps(steps);
  };

  const getAllAnimationPhaseSteps = () => {
    const selectedFlow = getCurrentFlow();
    if (!selectedFlow.demoSteps) return [];

    const rawSteps = selectedFlow.demoSteps.map((current) => current.steps);
    const flattenSteps = [].concat(...rawSteps);
    return formatSteps(flattenSteps);
  };

  const urlConvert = (flowName) => {
    const converted = flowName
      .toLowerCase()
      .replaceAll('_', '-')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .split(' ')
      .join('-');
    return converted;
  };

  const onKeyUp = (e) => {
    const { key } = e;
    if (key === 'Alt' && quickFlowPicker.show) {
      onFlowChange(quickFlowPicker.flow);
      setQuickFlowPicker({ ...quickFlowPicker, show: false });
    }
  };

  const onKeyDown = (e) => {
    const { altKey, key } = e;
    if (altKey && key === 'ArrowRight') {
      quickStepFlows(true);
    }
    if (altKey && key === 'ArrowLeft') {
      quickStepFlows();
    }
  };
  const quickStepFlows = (isForward) => {
    const { flow } = quickFlowPicker;
    const increase = isForward ? 1 : -1;
    const flows = Object.keys(FLOW_DATA);
    const index = flows.findIndex((item) => item === flow);
    const nextFlow = index + increase < flows.length && index + increase >= 0 ? index + increase : 0;
    setQuickFlowPicker({ show: true, flow: flows[nextFlow] });
  };
  const render = () => {
    const isAjaxRedirect = page === PAGES.CHALLENGE && challengeDisplay === AJAX_REDIRECT;
    const isChallengeModal = challengeDisplay === MODAL && showChallengeInModal;
    const steps = getCurrentAnimationPhaseSteps();
    const allSteps = getAllAnimationPhaseSteps();
    return (
      <div tabIndex="0" onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
        <input readOnly checked={showSideMenu} type="checkbox" id="drawer-toggle" name="drawer-toggle" />
        <SideMenuButton show={!showSideMenu} onSideMenuToggle={onSideMenuToggle} />
        <Console
          log={log}
          hideSideMenu={hideSideMenu}
          onResetForm={onResetForm}
          onFlowChange={onFlowChange}
          threeDsSettings={threeDsSettings}
          product={product}
          onCorrelationIdToggle={onCorrelationIdToggle}
          onChallengePositionChanged={onChallengePositionChanged}
          onDeclineRecoveryChange={onDeclineRecoveryChange}
          onPaymentsEnvChange={onPaymentsEnvChange}
          onClearLogPressed={() => setLog([])}
          onProductChange={onProductChange}
          onIsInspectEnabledChanged={onIsInspectEnabledChanged}
          history={history}
          section={section}
          onSectionSelected={onSectionSelected}
          urlConvert={urlConvert}
        />
        <div id="page-content">
          <StoreHeader isBackVisible={isBackVisible} reset={onBackPressed} />
          {renderMainPage()}
          <ThankYouPage
            show={page === PAGES.THANK_YOU}
            onBackPressed={onBackPressed}
            steps={allSteps}
            showFlowMessage={!showFlowAnimation}
          />
          <Challenge
            className="ch_width"
            isModal={isChallengeModal}
            show={isAjaxRedirect || isChallengeModal}
            challengeContainer={challengeContainer}
            onModalClose={onChallengeModalClose}
          />
          <ChatWidget />
        </div>
        <Loader
          show={showFlowAnimation}
          steps={showFlowAnimation ? steps : []}
          onFlowAnimationDone={onFlowAnimationDone}
        />
        <Notification show={isNotificationVisible} message={notificationMessage} />
        <QuickFlowPicker show={quickFlowPicker.show} flow={quickFlowPicker.flow} />
      </div>
    );
  };

  const renderMainPage = () => {
    const isCheckout = challengeDisplay === CHECKOUT && showChallengeInCheckout;

    if (page === PAGES.DATA_ENTRY) {
      return (
        <div className="data-entry-container">
          <DataEntryPage
            paymentTypes={paymentTypes}
            isBillingInfoDisabled={isBillingInfoDisabled}
            onCreditCardChanged={onCreditCardChanged}
            errorMessage={errorMessage}
            isLoading={isLoading}
            onResetForm={onResetForm}
            onFormInputChange={onFormInputChange}
            onFinish={dataEntryFinished}
            onChallengePositionChanged={onChallengePositionChanged}
            onCorrelationIdToggle={onCorrelationIdToggle}
            onPaymentChange={onPaymentChange}
            formFields={formFields}
            product={product}
            payment={paymentState}
            inputErrors={inputErrors}
            currency={currency}
            onSelectCurrency={onSelectCurrency}
            validateInputs={validateInputs}
            ccIcon={creditCardType.icon || ''}
          />
          <Challenge show={isCheckout} challengeContainer={challengeContainer} onModalClose={onChallengeModalClose} />
          <div className="cart">
            <OrderTable
              product={product}
              onAmountTotalChange={onAmountTotalChange}
              currency={currency}
              flow={threeDsSettings.flow}
              setRemovedFromCart={setRemovedFromCart}
            />
            <ForterLogo show={!showSideMenu} />
          </div>
        </div>
      );
    }
  };

  /**
   * push into the log a newmessage
   * @param {String} text log  message
   * @param {String} type LOG_TYPES found at enums module
   */
  const pushLogEntry = (text, type = 'default') => setLog((log) => [...log, { timestamp: Date.now(), text, type }]);

  /**
   * handle 3DS init error to fallback to a none 3DS flow
   * @param {Error} error
   */
  const handleInitError = (error) => {
    setIsNotificationVisible(false);
    setNotificationMessage('');
    setIsLoading(false);
    logActions.initError(pushLogEntry, error);
    isCheckoutButtonReady = true;
  };

  const handleUnrecognizedMessageFailure = () => {
    setIsNotificationVisible(false);
    setErrorMessage('3DS verification failed! unrecognized message was found');
    isCheckoutButtonReady = false;
    setIsLoading(false);
  };
  const creditCardAvailable = async (cc, expiry, firstName, lastName) => {
    const { should_3ds_init } = getCurrentFlow();
    // validate if its a 3DS flow
    if (!should_3ds_init) {
      isCheckoutButtonReady = true;
      orderId = cryptoJs.MD5(Date.now()).toString();
      return {};
    }
    // run a 3DS flow
    isCheckoutButtonReady = false;
    setIsNotificationVisible(true);
    setIsLoading(true);
    setNotificationMessage(NOTIFICATIONS.INIT_3DS);

    const { enableCorrelationId } = threeDsSettings;
    const initPayload = enableCorrelationId ? { cc, expiry, firstName, lastName } : { cc };

    logActions.threeDsInit(pushLogEntry, initPayload);
    const res = await initApi(initPayload)
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
    const { response, error } = res;
    orderId = res.orderId;

    // handle error from init
    if (error) {
      handleInitError(error);
      return {};
    }

    logActions.merchantServerResponseReceivedPassingToBrowser(pushLogEntry, response);
    const { THREE_DS_ONE, THREE_DS_TWO } = THREE_DS_VERSIONS;
    logActions.browserResponseReceived(pushLogEntry, response);

    // handle unrecognized message failure
    if (response.dsIdentifier === 'ACS_UNRECOGNIZED_MESSAGE_FAILURE') {
      handleUnrecognizedMessageFailure(response);
      return {};
    }
    if ([THREE_DS_ONE, THREE_DS_TWO].includes(response.version)) {
      logActions.browserCallingFtrInit3DS(pushLogEntry);
      setNotificationMessage(NOTIFICATIONS.SENDING_DATA_TO_ISSUER);
      window.ftr__.init3DS(response, (error, threeDSID) => {
        setIsNotificationVisible(false);
        setIsLoading(false);
        if (error) {
          logActions.errorOccurred(pushLogEntry, error);
          return;
        }
        setErrorMessage(undefined);
        logActions.issuerDataCollectionCompleted(pushLogEntry);
        threeDSServerTransID = threeDSID;
        if (pendingPayment) {
          validate(pendingPayment);
          pendingPayment = undefined;
        } else {
          isCheckoutButtonReady = true;
        }
      });
    } else {
      setIsNotificationVisible(false);
      if (pendingPayment) {
        validate(pendingPayment);
        pendingPayment = undefined;
      } else {
        isCheckoutButtonReady = true;
      }
    }
    return response;
  };
  const validateInputs = () => {
    validateFormFields(inputErrors, setInputErrors, formFields);
    const newCreditType = checkCardType();
    validateCreditCard(inputErrors, setInputErrors, formFields, newCreditType);
    validateExpiry(inputErrors, setInputErrors, formFields);
    validateCVV(inputErrors, setInputErrors, formFields);
    // return if any errors were found
    return Object.values(inputErrors).every((field) => field === null);
  };

  const getPayment = () => ({
    ...formFields,
    bin: formFields.cc.substr(0, 6),
    amount,
    currency,
  });

  const dataEntryFinished = async () => {
    if (window.checkoutTools) window.checkoutTools.declineRecovery.terminate();
    const isFormValid = validateInputs();
    if (isFormValid) {
      const payment = getPayment();
      const { enableCorrelationId } = threeDsSettings;

      if (enableCorrelationId) {
        payment.correlationId = correlationId;
        delete payment.cc;
      }
      setIsLoading(true);
      setPaymentState({ ...payment });

      if (isCheckoutButtonReady) {
        if (canRunInspectMode()) {
          setDemoPhaseState(payment);
          setShowFlowAnimation(true);
        } else {
          await validate(payment);
        }
      } else {
        pendingPayment = payment;
      }
    }
  };
  const onAmountTotalChange = (total) => {
    setAmount(total);
  };

  const validate = async (payment) => {
    if (payment.correlationId) {
      delete payment.expiry;
      delete payment.cvv;
      delete payment.bin;
    }
    payment.products = [
      {
        name: products[product].name,
        price: currency === 'USD' ? products[product].amount : products[product].eurAmount,
      },
    ];
    if (amount > 800) {
      payment.products[1] = {
        name: products.ONE_PLUS.name,
        price: currency === 'USD' ? products.ONE_PLUS.amount : products.ONE_PLUS.eurAmount,
      };
    }
    const UIversion = getDeclineRecoveryVersion() ? getDeclineRecoveryVersion() : 1;
    payment.threeDSServerTransID = threeDSServerTransID;
    logActions.payApiCall(pushLogEntry, payment);
    const res = await payApi(payment, orderId, product, threeDsSettings.flow, UIversion)
      .then((resp) => resp.json())
      .catch((error) => logActions.errorOccurred(pushLogEntry, error));
    recommendedProcessors = res.processors;

    let { response } = res.preAuth;
    const shouldRetry = ['purchase_with_retry'].includes(threeDsSettings.flow);
    const shouldFailWithFirstGatway = ['purchase_with_failed_psd2_tra_successful_3ds'].includes(threeDsSettings.flow);
    isApprovedFlow(payment, response);
    callAtlas(response);

    if (shouldFailWithFirstGatway) {
      response = res.postAuth.response;
      logActions.adaptiveAuthApiCallFailOnFirstGatePSD2(pushLogEntry, res);
    } else {
      logActions.adaptiveAuthApiCall(pushLogEntry, res, shouldRetry);
    }
    // The merchant server should extract the ThreeDS object
    // from Forter's response and send it back to the client,
    // the demo server sends the full response for demo purposes:
    let threeDSResponse = {};

    if (response.verificationMethod.verificationSpecificData) {
      threeDSResponse = response.verificationMethod.verificationSpecificData.ThreeDS;
      if (threeDSResponse && threeDSResponse.encodedChallengeRequest) {
        logActions.browserResponseReceived(pushLogEntry, threeDSResponse);
      }
    }

    if (response.flowsKit === undefined) {
      if (challengeDisplay === AJAX_REDIRECT) {
        setisBackVisible(true);
        setPage(PAGES.CHALLENGE);
      }
      if (challengeDisplay === CHECKOUT) {
        setShowChallengeInCheckout(true);
        document.getElementById('challenge').scrollIntoView();
      }
      if (challengeDisplay === MODAL) {
        setShowChallengeInModal(true);
      }
    }

    setIsLoading(false);
    if (threeDSResponse && threeDSResponse.encodedChallengeRequest) {
      setNotificationMessage(NOTIFICATIONS.TRIGGER_CHALLENGE);
      setIsNotificationVisible(true);
      setTimeout(() => {
        setIsNotificationVisible(false);
      }, 3000);

      logActions.callingFtrTriggerChallengeIfNeeded(pushLogEntry);
      setTimeout(() => {
        setDemoAnimationPhase(DEMO_PHASES.THREE_DS);
        window.ftr__.triggerChallengeIfNeeded(threeDSResponse, challengeContainer.current, onChallengeDone.bind(this));
      }, 3000);
    } else if (response.forterDecision === FORTER_DECISIONS.DECLINE && response.flowsKit === undefined) {
      logActions.noThreeDsTransactionDeclined(pushLogEntry);
      if (threeDsSettings.flow !== 'otp') {
        setErrorMessage(
          'Sorry! something went wrong, please try again with a different credit card (demo Issuer decline)',
        );
      }

      navigateHome();

      setThreeDsSettings({
        ...threeDsSettings,
        challengePosition: AJAX_REDIRECT,
      });
      setChallengeDisplay(AJAX_REDIRECT);
    } else if (
      response.forterDecision === FORTER_DECISIONS.NOT_REVIEWED &&
      !(payment.email || '').includes('decline-recovery')
    ) {
      logActions.noThreeDsTransactionNotReviewed(pushLogEntry);
      navigateHome();
      setErrorMessage('Transaction was not reviewed!');
    } else if (response.forterDecision === FORTER_DECISIONS.APPROVE) {
      const { authenticationValue } = threeDSResponse;
      if (authenticationValue) {
        logActions.threeDsAuthCompleted(pushLogEntry, authenticationValue);
      } else {
        const hasFlowsKit = response.flowsKit !== undefined;
        logActions.noThreeDsTransactionApproved(pushLogEntry, hasFlowsKit, formFields.country, threeDsSettings);

        const { type } = FLOW_DATA[threeDsSettings.flow];
        if (type === '3DS2.1_exemption') {
          logActions.callingGatewayWithExemption(pushLogEntry);
        }
      }
      const additionalThreeDsData = getAdditionalThreeDsData(response);
      if (additionalThreeDsData.transStatus === 'R') {
        logActions.bankRejectedAuth(pushLogEntry);
        setErrorMessage('Your bank has rejected the transaction');
        setPage(PAGES.DATA_ENTRY);
        return;
      }

      setShowChallengeInCheckout(false);
      setShowChallengeInModal(false);
      focusLog();

      if (response.flowsKit === undefined) {
        setPage(PAGES.THANK_YOU);
      }
    }
  };

  const getAdditionalThreeDsData = ({ verificationMethod }) => {
    const { verificationSpecificData = {} } = verificationMethod;
    return verificationSpecificData.additionalThreeDsData ? verificationSpecificData.additionalThreeDsData : {};
  };

  const focusLog = () => {
    const console = document.getElementsByClassName('console-container');

    if (console[0]) {
      console[0].focus();
    }
  };
  const handleVerifayError = ({ error }) => {
    setErrorMessage(`failed on 3DS verification - ${error.message}`);
    setChallengeDisplay(AJAX_REDIRECT);
    setShowChallengeInCheckout(false);
    setShowChallengeInModal(false);
    navigateHome();
  };
  const processChallengeDone = async (error, wasChallengePerformed, transStatus, cres) => {
    if (error) {
      logActions.errorOccurred(pushLogEntry, error);
      setErrorMessage('Sorry! something went wrong, please try again (demo: error)');
      return;
    }
    setErrorMessage(undefined);
    logActions.browserThreeDsChallengeStatus(pushLogEntry, error, wasChallengePerformed, transStatus, cres);
    // Bringing the AV to the client, just for demo purposes
    setNotificationMessage(NOTIFICATIONS.TRIGGER_VERIFY);
    setIsNotificationVisible(true);
    logActions.verifyApiCall(pushLogEntry, cres, orderId);
    const verificationResponse = await verifyApi(cres, orderId)
      .then((resp) => resp.json())
      .catch((error) => logActions.errorOccurred(pushLogEntry, error));
    setIsNotificationVisible(false);
    logActions.merchantServerResponseReceived(pushLogEntry, verificationResponse);
    const { forterDecision, verificationMethod = {} } = verificationResponse;

    if (verificationResponse.error) {
      handleVerifayError(verificationResponse);
      return;
    }
    const { verificationSpecificData = {} } = verificationMethod;
    logActions.browserChallengeCompleted(pushLogEntry);
    if (forterDecision === FORTER_DECISIONS.APPROVE) {
      // eslint-disable-next-line max-len,prettier/prettier
      const { authenticationValue } = verificationSpecificData.ThreeDS;
      logActions.threeDsAuthCompleted(pushLogEntry, authenticationValue, recommendedProcessors);
      setShowChallengeInCheckout(false);
      setShowChallengeInModal(false);
      focusLog();
      setPage(PAGES.THANK_YOU);
    } else if (forterDecision === FORTER_DECISIONS.DECLINE) {
      logActions.threeDsAuthCompletedWithDecline(pushLogEntry);
      setErrorMessage(
        'Sorry! something went wrong, please try again with a different credit card (demo: 3DS Authentication Failure)',
      );
      navigateHome();

      setThreeDsSettings({
        ...threeDsSettings,
        challengePosition: AJAX_REDIRECT,
      });
      setChallengeDisplay(AJAX_REDIRECT);
      setShowChallengeInCheckout(false);
      setShowChallengeInModal(false);
    } else if (forterDecision === FORTER_DECISIONS.NOT_REVIEWED) {
      logActions.threeDsAuthCompletedWithNotReviewed(pushLogEntry);
      navigateHome();

      setThreeDsSettings({
        ...threeDsSettings,
        challengePosition: AJAX_REDIRECT,
      });
      setChallengeDisplay(AJAX_REDIRECT);
      setShowChallengeInCheckout(false);
      setShowChallengeInModal(false);
    }
  };
  const onChallengeDone = async (error, wasChallengePerformed, transStatus, cres) => {
    if (canRunInspectMode()) {
      setDemoPhaseState({ error, wasChallengePerformed, transStatus, cres });
      setDemoAnimationPhase(DEMO_PHASES.THREE_DS);
      setShowFlowAnimation(true);
    } else {
      await processChallengeDone(error, wasChallengePerformed, transStatus, cres);
    }
  };

  const canRunInspectMode = () => {
    const { demoSteps } = getCurrentFlow();
    const { isInspectEnabled } = threeDsSettings;

    return isInspectEnabled && demoSteps;
  };

  const reset = () => {
    isCheckoutButtonReady = false;
    threeDSServerTransID = undefined;
    setIsLoading(false);
    navigateHome();
  };

  const navigateHome = () => {
    setisBackVisible(false);
    setPage(PAGES.DATA_ENTRY);
  };

  const onResetForm = () => {
    const resetObj = formHelper.getFormResetObj();
    setFormFields({ ...resetObj, country: formFields.country });
  };

  const onProductChange = (event) => {
    const { value } = event.target;
    setProduct(value);
  };

  const onChallengePositionChanged = ({ target }) => {
    setThreeDsSettings({ ...threeDsSettings, challengePosition: target.value });
    setChallengeDisplay(target.value);
  };

  const onDeclineRecoveryChange = ({ target }) => {
    setThreeDsSettings({ ...threeDsSettings, declineRecoveryDisplay: target.value });
    setDeclineRecoveryDisplay(target.value);
  };

  const onPaymentsEnvChange = ({ target }) => {
    if (window.checkoutTools) window.checkoutTools.declineRecovery.terminate();
    setThreeDsSettings({ ...threeDsSettings, paymentsEnv: target.value });
    setPaymentsEnv(target.value);
  };

  const onCorrelationIdToggle = ({ target }) => {
    setThreeDsSettings({ ...threeDsSettings, enableCorrelationId: target.checked });
  };

  const fillBillInfoForm = async () => {
    try {
      const flow = getCurrentFlow();

      if (flow) {
        const { cc, email, expiry = '10/25', firstName = 'John', lastName = 'Doe', cvv, phone } = flow;

        setFormFields({
          ...formFields,
          country: selectedCountry,
          cc,
          expiry,
          cvv: cvv !== undefined ? cvv : '456',
          email,
          firstName,
          lastName,
          address: '350 5th Avenue, New York, NY',
          zipCode: '10118',
          phone,
        });

        if (cc && cc.length > 12) {
          const res = await creditCardAvailable(cc, expiry, firstName, lastName);
          setCorrelationId(res.correlationId);
        }
      }
    } catch (error) {
      logActions.errorOccurred(pushLogEntry, error);
      setNotificationMessage('');
      setIsNotificationVisible(false);
    }
  };

  /**
   * start init phase after a flow selection
   * @param {*} flowType
   */
  const onFlowChange = (flowType) => {
    setRemovedFromCart(false);
    setProduct(getDefaultProduct(DEFAULT_FLOW));
    const chatWidget = document.getElementsByClassName('rcw-widget-container')[0];
    chatWidget.style.display = 'none';
    if (window.checkoutTools) window.checkoutTools.declineRecovery.terminate();
    setLog([]);
    setPage(PAGES.DATA_ENTRY);
    const flow = typeof flowType === 'string' ? flowType : threeDsSettings.flow;

    // load flow defaults
    const { product, showSavedPayments = false, isBillingInfoDisabled, hideCVV, demoSteps, type } =
      FLOW_DATA[flow] || {};
    let disableProductSelection = false;
    if (flowPath) {
      history.replace({ pathname: `${urlConvert(FLOW_DATA[flow].title)}`, search: history.location.search });
    } else {
      history.replace({
        pathname: `${urlConvert(type)}/${urlConvert(FLOW_DATA[flow].title)}`,
        search: history.location.search,
      });
    }

    // only PAYMENTS section should see the pay now/later payment types
    const payments =
      type === 'PAYMENTS' || type === 'DECLINE_RECOVERY' || type === 'DECLINE_RECOVERY_DEMO'
        ? PAYMENTS[selectedCountry]
        : [PAYMENTS[selectedCountry].find((item) => item.type === 'CC')];
    setPaymentTypes(payments);
    if (product) {
      setProduct(product);
      disableProductSelection = true;
    }

    setErrorMessage(undefined);
    if (flow) {
      if (demoSteps) {
        const [firstPhase] = demoSteps;
        const { type } = firstPhase;
        setDemoAnimationPhase(type);
      }

      setThreeDsSettings({
        ...threeDsSettings,
        flow,
        disableProductSelection,
      });
    }
    setIsSavePaymentsVisible(showSavedPayments);
    setShowSavedPayments(showSavedPayments);
    setIsBillingInfoDisabled(isBillingInfoDisabled);
    setHideCVV(hideCVV);
  };

  const onSectionSelected = (event) => {
    event.preventDefault();
    const [type, title] = FLOW_TYPES[event.target.value];
    history.push({
      pathname: `/${urlConvert(type)}`,
      search: history.location.search,
    });
    // make sure chat type only shown on 'decline recovery demo' flows
    if (declineRecoveryDisplay === 'chat' && type !== 'DECLINE_RECOVERY_DEMO') {
      setDeclineRecoveryDisplay('modal');
    }
    setSection({ type, title, index: event.target.value });
  };

  const onChallengeModalClose = () => {
    setShowChallengeInModal(false);
    reset();
  };

  const onSelectCurrency = (target) => {
    const value = target.getAttribute('value');
    setCurrency(value);
  };

  const onFormInputChange = (target) => {
    let value;
    if (target.value || target.value === '') {
      value = target.value;
    } else {
      value = target.value || target.getAttribute('value');
    }
    const { id } = target;
    setFormFields({ ...formFields, [id]: value });
    if (id === 'country') {
      if (threeDsSettings.flow === 'decline_recovery') {
        const newEmail =
          value === 'OTHER' ? 'decline-recovery@forter.com' : `decline-recovery-${value.toLowerCase()}@forter.com`;
        setFormFields({ ...formFields, email: newEmail, [id]: value });
      }
      const { type } = getCurrentFlow();
      setSelectedCountry(value);
      const payments =
        type === 'PAYMENTS' || type === 'DECLINE_RECOVERY' || type === 'DECLINE_RECOVERY_DEMO'
          ? PAYMENTS[value]
          : [PAYMENTS[value].find((item) => item.type === 'CC')];
      setPaymentTypes(payments);
    }
    if (id === 'cc') {
      checkCardType();
    }
    // remove error when user is typing
    inputErrors[id] = null;
  };

  const checkCardType = () => {
    const type = Object.keys(CREDIT_CARD_MASKING).find(
      (cardType) => CREDIT_CARD_MASKING[cardType].pattern.test(formFields.cc),
      this,
    );
    if (type && CREDIT_CARD_MASKING[type].valid_length.includes(formFields.cc.length)) {
      return type;
    }
    return null;
  };

  const onCreditCardChanged = ({ target }) => {
    onFormInputChange(target);
    const cc = target.value;

    if (cc.length > 12 && formFields.cc !== cc) {
      const { expiry, firstName, lastName } = formFields;

      creditCardAvailable(cc, expiry, firstName, lastName);
    }
  };

  const onBackPressed = () => {
    onResetForm();
    setLog([]);
    setCorrelationId(false);

    setThreeDsSettings({
      challengePosition: challengeDisplay,
      flow: '',
      enableCorrelationId: false,
      disableProductSelection: false,
      isInspectEnabled: threeDsSettings.isInspectEnabled,
    });
    reset();
  };

  const hideSideMenu = () => {
    setShowSideMenu(false);
  };

  const onIsInspectEnabledChanged = ({ target }) => {
    setThreeDsSettings({ ...threeDsSettings, isInspectEnabled: target.checked });
  };

  const onSideMenuToggle = () => {
    setShowSideMenu(!showSideMenu);
  };

  const onCheckout = async (demoPhaseState) => {
    setShowFlowAnimation(false);
    await validate({ ...demoPhaseState });
  };

  const onThreeDs = async (demoPhaseState) => {
    const { error, wasChallengePerformed, transStatus, cres } = demoPhaseState;

    await processChallengeDone(error, wasChallengePerformed, transStatus, cres);
    setShowFlowAnimation(false);
  };

  const onOtp = async () => {
    const payment = getPayment();
    payment.email = 'approve@forter.com';
    logActions.payApiCallAfterOtp(pushLogEntry, payment);
    const res = await payApi(payment, orderId, product, threeDsSettings.flow)
      .then((resp) => resp.json())
      .catch((error) => logActions.errorOccurred(pushLogEntry, error));
    logActions.adaptiveAuthApiCallAfterOtp(pushLogEntry, res);
    logActions.noThreeDsTransactionApprovedOTP(pushLogEntry);
    setPage(PAGES.THANK_YOU);
    setErrorMessage(undefined);
    setShowFlowAnimation(false);
  };

  const onFlowAnimationDone = async () => {
    const { CHECKOUT, THREE_DS, OTP_CHALLENGE } = DEMO_PHASES;
    const resolvers = {
      [CHECKOUT]: onCheckout,
      [THREE_DS]: onThreeDs,
      [OTP_CHALLENGE]: onOtp,
    };

    await resolvers[demoAnimationPhase](demoPhaseState);
  };

  const isApprovedFlow = (payment, response) => {
    if (
      (formFields.email.includes('decline-recovery') &&
        (payment.cvv === DR_SUCCESS_FLOWS.CVV ||
          removedFromCart ||
          formFields.address === DR_SUCCESS_FLOWS.ADDRESS ||
          formFields.firstName === DR_SUCCESS_FLOWS.FIRST_NAME ||
          formFields.cc === DR_SUCCESS_FLOWS.CARD_NUMBER ||
          payment.expiry === DR_SUCCESS_FLOWS.EXPIRY)) ||
      (currency === 'EUR' && formFields.email === 'decline-recovery-technical-decline@forter.com')
    ) {
      response.flowsKit = undefined;
    }
  };

  const callAtlas = (response) => {
    if (response.flowsKit && response.flowsKit.type === 'DECLINE_RECOVERY') {
      window.document.getElementById('iframecontainer').style.height = '0';
      if (declineRecoveryDisplay === 'chat') {
        const errorCode = response.flowsKit.declineRecovery ? response.flowsKit.declineRecovery.errorCode : '';
        if (errorCode) {
          window.checkoutTools.declineRecovery.openWithData(
            {
              type: 'DECLINE_RECOVERY',
              url: 'https://954fce831acf.cdn-f.checkouttools.com/staging/assets/declineRecovery.html',
              encData: 'eyJjb3VudHJ5IjoiR0UiLCJhY3Rpb24iOiJET05UX0hPTk9SIn0=',
              declineRecovery: declineRecoveryBot[errorCode],
            },
            getCallbackOptions({ setFormFields, formFields }),
            getOptions(declineRecoveryDisplay),
          );
        }
      } else {
        window.checkoutTools.declineRecovery.open(
          getCallbackOptions({ setFormFields, formFields }),
          getOptions(declineRecoveryDisplay),
        );
      }
      if (formFields.email === 'decline-recovery-insufficient-funds1@forter.com') {
        formFields.email = 'decline-recovery-insufficient-funds2@forter.com';
      }
    }
  };

  const onPaymentChange = ({ target }) => {
    const { id } = target;

    setPaymentTypes(
      paymentTypes.map((payment) => {
        payment.checked = payment.title === id;
        return payment;
      }),
    );
  };

  return render();
}

export default App;
