import React from 'react';
import FormField from './form-field';

export default function AchDetails() {
  return (
    <div className="expiry_cvv">
      <FormField placeholder="1234 1234 1234 1234 5">Account number</FormField>
      <FormField placeholder="123456789">ABA routing number</FormField>
    </div>
  );
}
