/* eslint-disable import/no-dynamic-require,global-require */
import React, { useState } from 'react';
import '../styles/order-table.css';
import products from '../helpers/products';
import { CURRENCY } from '../helpers/enums';

function OrderTable({ product, onAmountTotalChange, currency, flow, setRemovedFromCart }) {
  const isTwoProducts = flow === 'decline_recovery_credit_floor' || flow === 'decline_recovery_insufficient_funds';
  const { amount, eurAmount, image, name, tax, eurTax, shipping, eurShipping, description } = products[product];

  const prodAmount = parseInt(amount, 10);
  const eurProdAmount = parseInt(eurAmount, 10);
  const [secondAmount, setSecondAmount] = useState(parseInt(products.ONE_PLUS.amount, 10));
  const [eurSecondAmount, setEurSecondAmount] = useState(parseInt(products.ONE_PLUS.eurAmount, 10));
  const [isSecondProductActive, setIsSecondProductActive] = useState(true);

  const getTotal = () => {
    if (currency === 'USD') {
      let total = prodAmount + parseInt(shipping, 10) + parseInt(tax, 10);
      if (isTwoProducts) {
        total += secondAmount;
      }
      onAmountTotalChange(total);
      return total;
    }
    // EURO
    let total = eurProdAmount + parseInt(eurShipping, 10) + parseInt(eurTax, 10);
    if (isTwoProducts) {
      total += eurSecondAmount;
    }
    onAmountTotalChange(total);
    return total;
  };

  const getSubTotal = () => {
    if (isTwoProducts) {
      return currency === 'USD' ? prodAmount + secondAmount : eurProdAmount + eurSecondAmount;
    }
    return currency === 'USD' ? prodAmount : eurProdAmount;
  };

  const changeCartItems = () => {
    setIsSecondProductActive(!isSecondProductActive);
    // eslint-disable-next-line radix
    setSecondAmount(secondAmount === 0 ? parseInt(products.ONE_PLUS.amount, 10) : 0);
    setEurSecondAmount(eurSecondAmount === 0 ? parseInt(products.ONE_PLUS.eurAmount, 10) : 0);
    setRemovedFromCart(isSecondProductActive);
  };

  const renderSecondProduct = () => (
    <div className="order">
      <div className="pic-and-title">
        <button id="remove" type="button" className="button is-small remove" onClick={changeCartItems}>
          {isSecondProductActive ? 'Remove' : 'Return to cart'}
        </button>
        <img
          className={`product ${isSecondProductActive ? '' : 'not-in-cart'}`}
          src={require('../assets/oneplus6t.jpg')}
          alt="OnePlus"
        />
        <div className={`item-label ${isSecondProductActive ? '' : 'not-in-cart'}`}>
          {products.ONE_PLUS.description}
        </div>
      </div>
      <div className={`prod-price ${isSecondProductActive ? '' : 'not-in-cart'}`}>
        {CURRENCY[currency].sign}
        {currency === 'USD' ? products.ONE_PLUS.amount : products.ONE_PLUS.eurAmount}
      </div>
    </div>
  );

  return (
    <div className="order-card">
      <div className="order">
        <div className="pic-and-title">
          <img className="product" src={require(`../assets/${image}.jpg`)} alt={name} />
          <div className="item-label">{description}</div>
        </div>
        <div className="prod-price">
          {CURRENCY[currency].sign}
          {currency === 'USD' ? amount : eurAmount}
        </div>
      </div>
      {isTwoProducts ? renderSecondProduct() : ''}
      <div className="calc-description">
        <div className="desc-section">
          <div>Subtotal</div>
          <div>
            {CURRENCY[currency].sign}
            {getSubTotal()}
          </div>
        </div>
        <div className="desc-section">
          <div>Shipping</div>
          <div>
            {CURRENCY[currency].sign}
            {currency === 'USD' ? shipping : eurShipping}
          </div>
        </div>
        <div className="desc-section">
          <div>Taxes</div>
          <div>
            {CURRENCY[currency].sign}
            {currency === 'USD' ? tax : eurTax}
          </div>
        </div>
      </div>
      <div className="total-calc">
        <div>Total</div>
        <div>
          <span className="total">
            {CURRENCY[currency].sign}
            {getTotal()}
          </span>
        </div>
      </div>
    </div>
  );
}

export default OrderTable;
