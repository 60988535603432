/* eslint-disable */
export const FLOW_DATA = {
  three_ds_21_approve_frictionless: {
    title: 'Approve (Frictionless)',
    cc: '5222220000000005',
    email: 'your.name@gmail.com',
    // cc: '5333330000000008',
    expiry: '08/22',
    type: '3DS2.1',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_verification_required: {
    title: 'Decline Verification Required',
    cc: '4138490000000000',
    email: 'your.name@gmail.com',
    type: '3DS2.1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_21_static_text_challenge: {
    title: 'Decline Static Text Challenge',
    cc: '5111220000000009',
    email: 'your.name@gmail.com',
    type: '3DS2.1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_21_dynamic_text_challenge: {
    title: 'Decline Dynamic Text Challenge',
    cc: '5111330000000006',
    email: 'your.name@gmail.com',
    type: '3DS2.1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_21_multiple_choice_challenge: {
    title: 'Decline Multiple Choice Challenge',
    cc: '5247210000000007',
    email: 'your.name@gmail.com',
    type: '3DS2.1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_21_out_of_band_challenge: {
    title: 'Decline Out Of Band',
    cc: '5111410000000006',
    email: 'your.name@gmail.com',
    type: '3DS2.1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_21_bank_reject_authentication: {
    title: 'Decline Bank Reject Authentication',
    cc: '5248480000200084',
    email: 'decline@forter.com',
    type: '3DS2.1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_1_approve_with_verification: {
    title: 'Approve (Frictionless)',
    cc: '2539409308331742',
    firstName: 'Thomas',
    lastName: 'Jefferson',
    expiry: '06/24',
    currencyCode: '978',
    trackId: '1234567',
    email: 'your.name@gmail.com',
    type: '3DS1',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_1_decline_verification_required: {
    title: 'Decline Verification Required',
    cc: '5422882800700007',
    firstName: 'Thomas',
    lastName: 'Jefferson',
    expiry: '06/24',
    currencyCode: '978',
    trackId: '1234567',
    email: 'your.name@gmail.com',
    type: '3DS1',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  three_ds_1_unsupported_card: {
    title: 'Approve Unsupported Card',
    cc: '4983582387098270',
    firstName: 'Thomas',
    lastName: 'Jefferson',
    expiry: '06/24',
    currencyCode: '978',
    trackId: '1234567',
    email: 'approve@forter.com',
    type: '3DS1',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  forter_approve: {
    title: 'Approve',
    cc: '4111111111111110',
    email: 'approve@forter.com',
    type: 'FORTER',
    transactionStatus: 'approve',
  },
  forter_decline: {
    title: 'Decline',
    cc: '4111111111111110',
    email: 'decline@forter.com',
    type: 'FORTER',
    transactionStatus: 'decline',
  },
  three_ds_21_low_value_exemption: {
    title: 'Approve Low Value Exemption',
    cc: '5248480000201017',
    email: 'your.name@gmail.com',
    type: '3DS2.1_exemption',
    product: 'CASE',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_transaction_risk_analysis: {
    title: 'Approve Transaction Risk Analysis',
    cc: '5248480000200019',
    email: 'your.name@gmail.com',
    type: '3DS2.1_exemption',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_secure_corporate: {
    title: 'Approve Secure Corporate',
    cc: '5248480000201066',
    email: 'your.name@gmail.com',
    type: '3DS2.1_exemption',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_trusted_beneficiary: {
    title: 'Approve Trusted Beneficiary',
    cc: '5248480000201090',
    email: 'your.name@gmail.com',
    type: '3DS2.1_exemption',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_anonymous_prepaid_card: {
    title: 'Approve Anonymous Prepaid Card',
    cc: '5248480000200027',
    email: 'your.name@gmail.com',
    type: '3DS2.1_out_of_scope',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_merchant_initiated_transaction: {
    title: 'Approve Merchant Initiated Transaction',
    cc: '5248480000200076',
    email: 'your.name@gmail.com',
    type: '3DS2.1_out_of_scope',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_one_leg_out_eea: {
    title: 'Approve One Leg Out EEA',
    cc: '5248480000200001',
    email: 'your.name@gmail.com',
    type: '3DS2.1_out_of_scope',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  three_ds_21_mail_order_telephone_order: {
    title: 'Approve Mail Order Telephone Order',
    cc: '5248480000200092',
    email: 'your.name@gmail.com',
    type: '3DS2.1_out_of_scope',
    transactionStatus: 'approve',
    should_3ds_init: true,
  },
  not_reviewed: {
    title: 'Not Reviewed',
    cc: '4833320000000007',
    email: 'notreviewed@forter.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  error_on_init: {
    title: 'Error On Init',
    cc: '4130410522210687',
    email: 'approve@forter.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  error_on_3ds_flow: {
    title: 'AREQ error',
    cc: '4965526389203778',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  error_in_verify: {
    title: 'Error In Verify',
    cc: '4912130000000004',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  reject_by_network: {
    title: 'Reject By Network',
    cc: '4407900000000002',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  invalid_card_number: {
    title: 'Invalid Card Number',
    cc: '4811110000000008',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  invalid_merchant_category_code: {
    title: 'Invalid Merchant Category Code',
    cc: '4999990000000007',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  acs_error_unrecognized_message: {
    title: 'Unrecognized Message',
    cc: '5111500000000004',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  transaction_status_u: {
    title: 'Transaction Status U',
    cc: '5270140000000006',
    email: 'your.name@gmail.com',
    type: '3DS2.1 Errors',
    transactionStatus: 'verification-req',
    should_3ds_init: true,
  },
  purchase_one_processor: {
    title: 'One Processor',
    cc: '5333330000000008',
    expiry: '12/22',
    email: 'force_payment_routing@forter.com',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          '3DS: not required for Risk, regulation nor auth. rate uplift',
          'Calculating optimal route',
          'Recommendation: PROCESSOR1 (no 3DS)',
          'Successfully authoirzed with PROCESSOR1',
        ],
      },
    ],
  },
  purchase_with_retry: {
    title: 'Processor Retry',
    cc: '5333330000000008',
    expiry: '08/22',
    email: 'force_payment_routing@forter.com',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          '3DS: not required for Risk, regulation nor auth. rate uplift',
          'Calculating optimal route',
          'Recommendation: PROCESSOR1 (no 3DS)',
          'Declined, calculating a new route',
          'Recommendation: Cascading (retry) with PROCESSOR2 (no 3DS)',
          'Successfully authoirzed with PROCESSOR2',
        ],
      },
    ],
  },
  returning_user_with_forter: {
    title: 'Returning User 1 Click Checkout',
    cc: '5333330000000008',
    expiry: '08/22',
    email: 'force_payment_routing@forter.com',
    // email: 'approve@forter.com',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    demoSteps: [
      {
        type: 'checkout',
        steps: ['Returning user enjoy 1-click checkout, purchase with saved details'],
      },
    ],
    hideCVV: true,
    showSavedPayments: true,
    showRecoveryModal: true,
    isBillingInfoDisabled: true,
  },
  returning_user_without_forter: {
    title: 'Returning User 1 Click Checkout',
    cc: '4111111111111110',
    email: 'approve@forter.com',
    cvv: '',
    type: 'NO_FORTER',
    transactionStatus: 'approve',
    showSavedPayments: true,
    showRecoveryModal: true,
    isBillingInfoDisabled: true,
  },
  decline_recovery_invalid_cvv: {
    title: 'Invalid CVV',
    cc: '4111111111111110',
    email: 'decline-recovery-invalid-cvv@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid CVV',
          'Generating a personalized recommendation to recover the transaction - re type cvv details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_invalid_address: {
    title: 'Invalid address',
    cc: '4111111111111110',
    email: 'decline-recovery-invalid-address@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY_DEMO',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction',
          'Recommending to retype address details and try again',
        ],
      },
    ],
  },
  decline_recovery_insufficient_funds: {
    title: 'Insufficient Funds',
    cc: '4111111111111110',
    email: 'decline-recovery-insufficient-funds1@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY_DEMO',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to insufficient funds',
          'Generating a personalized recommendation to recover the transaction',
          'Recommending to try another card',
        ],
      },
    ],
  },
  decline_recovery_contact_bank: {
    title: 'Contact Bank',
    cc: '4111111111111110',
    email: 'decline-recovery-contact-the-bank@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY_DEMO',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined',
          'Generating a personalized recommendation to recover the transaction',
          'Recommending to  reply to the bank SMS or use another card',
        ],
      },
    ],
  },
  decline_recovery_failed_3ds: {
    title: '3DS Failed',
    cc: '4111111111111110',
    email: 'decline-recovery-failed-3ds@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_expired: {
    title: 'Expired',
    cc: '4111111111111110',
    email: 'decline-recovery-expired@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_restricted_card: {
    title: 'Restricted Card',
    cc: '4111111111111110',
    email: 'decline-recovery-restricted-card@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_invalid_cc: {
    title: 'Invalid CC',
    cc: '4111111111111110',
    email: 'decline-recovery-invalid-cc@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_processor_decline: {
    title: 'Processor Decline',
    cc: '4111111111111110',
    email: 'decline-recovery-processor-decline@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_credit_floor: {
    title: 'Credit Floor',
    cc: '4111111111111110',
    email: 'decline-recovery-credit-floor@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_technical_decline: {
    title: 'Technical Decline',
    cc: '4111111111111110',
    email: 'decline-recovery-technical-decline@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_anonymous_prepaid_card: {
    title: 'Anonymous Prepaid Card',
    cc: '4111111111111110',
    email: 'decline-recovery-anonymous-prepaid-card@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_suspected_fraud: {
    title: 'Suspected Fraud',
    cc: '4111111111111110',
    email: 'decline-recovery-suspected-fraud2@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_merchant_rules: {
    title: 'Merchant Rules',
    cc: '4111111111111110',
    email: 'decline-recovery-merchant-rules@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_not_permitted: {
    title: 'Not Permitted',
    cc: '4111111111111110',
    email: 'decline-recovery-not-permitted@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  decline_recovery_do_not_honor: {
    title: 'Do Not Honor',
    cc: '4111111111111110',
    email: 'decline-recovery-do-not-honor@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY_DEMO',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined',
          'Generating a personalized recommendation to recover the transaction',
          'Recommending to  reply to the bank SMS or  use another card',
        ],
      },
    ],
  },
  decline_recovery_other: {
    title: 'Other',
    cc: '4111111111111110',
    email: 'decline-recovery-other@forter.com',
    cvv: '111',
    type: 'DECLINE_RECOVERY',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Issuer decision: declined due to invalid address',
          'Generating a personalized recommendation to recover the transaction - retype address details and try again',
          'Recommending to complete the purchase with a local payment method',
        ],
      },
    ],
  },
  otp: {
    title: 'SMS verification',
    cc: '4111111111111110',
    phone: '972546230172',
    email: 'smsverification@forter.com',
    cvv: '873',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    showRecoveryModal: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: high risk transaction',
          'Recommendation: additional verification',
          'Selecting the recommended challenge based on user preference and country',
          'Updating merchant UI to reflect challenge requirement',
          'Sending an OTP via SMS',
        ],
      },
      {
        type: 'otp_challenge',
        steps: ['Challenge completed successfully - Forter approves'],
      },
    ],
  },
  purchase_with_psd2_tra_exemption: {
    title: 'PSD2 TRA Exemption',
    cc: '5248480000200019',
    expiry: '10/25',
    cvv: '456',
    email: 'force_payment_routing@forter.com',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    should_3ds_init: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved, PSD2 regulation',
          '3DS requires SCA challenge, ask for a TRA exemption due to low risk to avoid friction',
          'Evaluating best processor to approve the exemption',
          'Recommendation: PROCESSOR1 (with TRA exemption request)',
          'Successfully authoirzed with PROCESSOR1 (TRA exemption accepted)',
        ],
      },
    ],
  },
  purchase_with_failed_psd2_tra_successful_3ds: {
    title: 'PSD2 Exemption Fail & SCA',
    // cc: '5248480000200019',
    cc: '5248480000201108',
    expiry: '10/25',
    cvv: '456',
    email: 'force_payment_routing@forter.com',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    should_3ds_init: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved, PSD2 regulation',
          '3DS requires SCA challenge, ask for a TRA exemption due to low risk',
          'Evaluating best processor to approve the exemption',
          'Recommendation: PROCESSOR1 with TRA exemption',
          'TRA exemption rejected by PROCESSOR1, attempting TRA exemption with PROCESSOR2',
          'TRA exemption rejected by PROCESSOR2, attempting 3DS challenge to recover the decline',
        ],
      },
      {
        type: 'three_ds',
        steps: [
          'Challenge successful, route to PROCESSOR1 with 3DS token',
          'Successfully authoirzed with PROCESSOR1 (with 3DS)',
        ],
      },
    ],
  },
  purchase_with_psd2_forter_liability: {
    title: 'PSD2 Forter Liability',
    cc: '5248480000200019',
    expiry: '09/22',
    email: 'force_payment_routing@forter.com',
    type: 'PAYMENTS',
    transactionStatus: 'approve',
    should_3ds_init: true,
    demoSteps: [
      {
        type: 'checkout',
        steps: [
          'Fraud decision: approved',
          'Merchant required 3DS to shift liability',
          '3DS will require challenge, Forter takes liability in order to provide a frictionless experience',
          'Recommendation: PROCESSOR1 with TRA exemption (no 3DS)',
          'Successfully authoirzed with PROCESSOR1 (TRA exemption accepted)',
        ],
      },
    ],
  },
};

export const SECTION_TYPES = {
  NO_FORTER: 'NO_FORTER',
  '3DS2.1': '3DS2.1',
  '3DS2.1 Errors': '3DS2.1 Errors',
  '3DS2.1_exemption': '3DS2.1_exemption',
  '3DS2.1_out_of_scope': '3DS2.1_out_of_scope',
  '3DS1': '3DS1',
  DECLINE_RECOVERY: 'DECLINE_RECOVERY',
  FORTER: 'FORTER',
  PAYMENTS: 'PAYMENTS',
  DECLINE_RECOVERY_DEMO: 'DECLINE_RECOVERY_DEMO',
};
export const FLOW_TYPES = [
  [SECTION_TYPES.DECLINE_RECOVERY, 'Decline Recovery'],
  [SECTION_TYPES.PAYMENTS, 'Payments'],
  [SECTION_TYPES.NO_FORTER, 'Without Forter'],
  [SECTION_TYPES.DECLINE_RECOVERY_DEMO, 'Decline Recovery Demo'],
  [SECTION_TYPES['FORTER'], 'Forter (No 3DS)'],
  [SECTION_TYPES['3DS2.1'], '3DS 2.1 (EMV)'],
  [SECTION_TYPES['3DS2.1 Errors'], '3DS 2.1 (Errors)'],
  [SECTION_TYPES['3DS2.1_exemption'], '3DS 2.1 (EMV) - Exemptions'],
  [SECTION_TYPES['3DS2.1_out_of_scope'], '3DS 2.1 (EMV) - Out Of Scope'],
  [SECTION_TYPES['3DS1'], '3DS 1.02'],
];
