import React from 'react';
import '../styles/flow-messages.css';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FlowMessages({ steps = [], isAnimation }) {
  const delayFactor = 2;

  if (!steps || steps.length < 1) {
    return null;
  }

  if (!steps || steps.length < 1) {
    return null;
  }

  return (
    <div className="event-container">
      <span className="flows-title">Inside the Forter engine</span>
      <ul className="steps is-vertical is-short">
        {steps.map((step, index) => (
          <li className="step">
            {isAnimation ? (
              <div
                className="loader"
                style={{ animationDelay: `${index * delayFactor}s` }}
                onAnimationEnd={(e) => {
                  e.target.style.display = 'none';
                }}
                onAnimationStart={(e) => {
                  e.target.style.opacity = '1';
                }}
              />
            ) : (
              ''
            )}
            <FontAwesomeIcon
              className={`checkIcon ${isAnimation ? 'animation2' : ''}`}
              style={{ animationDelay: `${(index + 1) * delayFactor}s` }}
              icon={faCheckCircle}
            />
            <span
              name={index}
              key={step}
              className={`steps-marker ${isAnimation ? 'animation' : ''}`}
              style={{ animationDelay: `${(index + 1) * delayFactor}s` }}
            >
              {step}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FlowMessages;
