import React from 'react';
import styles from '../styles/loader.module.css';
import FlowMessages from './flow-messages';

export default function Loader({ show = true, backdrop = true, steps = [], onFlowAnimationDone }) {
  const isShowSteps = () => steps.length !== 0;
  return (
    <div className={`modal ${show ? 'is-active' : ''}`}>
      <div className={`${styles.dropshadow} ${backdrop ? 'modal-background' : ''}`} />
      <div className={`modal-content ${styles.box}`} onClick={onFlowAnimationDone}>
        <div>{isShowSteps ? <FlowMessages steps={steps} isAnimation /> : <div className={styles.loader} />}</div>
      </div>
    </div>
  );
}
