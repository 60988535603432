import { FLOW_TYPES, FLOW_DATA } from './flows';

const getUrlPrams = () => new URLSearchParams(window.location.search);

export const getDefaultSection = () => {
  const [defaultSection] = FLOW_TYPES;
  const urlParams = getUrlPrams();
  const flowFromUrl = urlParams.get('flow');
  let selectedIndex = 0;

  if (flowFromUrl) {
    const section = FLOW_DATA[flowFromUrl].type;

    const selectedSection = FLOW_TYPES.find(([type], index) => {
      if (section === type) {
        selectedIndex = index;
        return true;
      }
      return false;
    });
    return [...selectedSection, selectedIndex];
  }
  return [...defaultSection, selectedIndex];
};
export const getDefaultFlow = (defaultFlow) => {
  const urlParams = getUrlPrams();
  return urlParams.get('flow') || defaultFlow;
};
export const getConsoleState = () => {
  const urlParams = getUrlPrams();
  return urlParams.get('side_open') === 'true' || urlParams.get('side_open') == null;
};
export const getDeclineRecoveryVersion = () => {
  const urlParams = getUrlPrams();
  return urlParams.get('decVersion') || '2';
};
export const getHas3ds = () => {
  const urlParams = getUrlPrams();
  return urlParams.get('3ds') === 'true';
};
export const getDefaultProduct = (defaultFlow) => {
  const flow = getDefaultFlow(defaultFlow);
  const { product } = FLOW_DATA[flow];

  return product || 'CHARGER';
};
export const isProductSelectionDisabled = (defaultFlow) => {
  const flow = getDefaultFlow(defaultFlow);
  const { product } = FLOW_DATA[flow];

  return product !== undefined;
};
