import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faArrowLeft, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/store-header.css';
import { useMainContext } from './stateProvider';

function StoreHeader({ reset, isBackVisible = false }) {
  const { isSavePaymentsVisible } = useMainContext();

  const renderUserAccount = () => (
    <div className="user-container">
      <div className="username">{isSavePaymentsVisible ? 'John Doe' : 'Sign In'}</div>
      <div className="avatar">
        <FontAwesomeIcon icon={faUserCircle} />
      </div>
    </div>
  );
  const renderBackButton = () => (
    <div className="back-button tooltip is-tooltip-right" data-tooltip="Back To Checkout" onClick={reset}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </div>
  );
  const render = () => (
    <div className="top-bar">
      <div className="brand-container">
        {isBackVisible && renderBackButton()}
        <div className="brand-logo">
          <FontAwesomeIcon icon={faRocket} />
        </div>
        <div className="brand-name">Rocket Electronics</div>
      </div>
      {renderUserAccount()}
    </div>
  );
  return render();
}

export default StoreHeader;
