import httpHelper from './httpHelper';
import products from './products';
import { DR_SUCCESS_FLOWS } from './enums';

const API_TYPES = {
  PAY: 'pay',
  VERIFY: 'verify',
  INIT: 'init',
};

/**
 * call Forter pay api
 * @param {Object} payment payment object
 * @param {String} orderId order Id
 * @param {String} product product enum
 * @param flow
 * @param uiVersion
 * @returns payment response
 */
export const payApi = (payment, orderId, product, flow, uiVersion) => {
  if (payment.email.includes('decline-recovery')) {
    payment.checkoutToolsSessionId = window.checkoutTools.getSessionId();
    if (
      /* eslint-disable prettier/prettier */
      payment.cvv === DR_SUCCESS_FLOWS.CVV
      || payment.cc === DR_SUCCESS_FLOWS.CARD_NUMBER
      || product === DR_SUCCESS_FLOWS.PRODUCT
      || payment.expiry === DR_SUCCESS_FLOWS.EXPIRY
    ) {
      payment.verificationResults = {
        avsStreetResult: 'M',
        avsZipResult: 'M',
        cvvResult: 'M',
        authorizationCode: 'ABCD123',
        processorResponseCode: '1000',
        processorResponseText: 'success',
      };
    }
  }
  const body = JSON.stringify({
    payment,
    orderId,
    product: products[product].description,
    flow,
    uiVersion,
  });
  return httpHelper.send({
    path: API_TYPES.PAY,
    body,
  });
};

/**
 * call Forter verify api
 * @param {String} cres
 * @param {String} orderId order Id
 * @returns verification response
 */
export const verifyApi = (cres, orderId) => {
  const body = JSON.stringify({
    cres,
    orderId,
  });
  return httpHelper.send({
    path: API_TYPES.VERIFY,
    body,
  });
};

/**
 * call Forter init api
 * @param {Object} payload billing info
 * @returns init response
 */
export const initApi = (payload) => {
  const body = JSON.stringify(payload);
  return httpHelper.send({
    path: API_TYPES.INIT,
    body,
  });
};
