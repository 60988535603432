import React from 'react';
import FormField from './form-field';
import SavedPayments from './savedPayments';
import { useMainContext } from './stateProvider';

function CreditCardDetails({
  formFields,
  onFormInputChange,
  onCreditCardChanged,
  inputErrors,
  validateInputs,
  ccIcon,
}) {
  const { cc, cvv, expiry } = formFields;
  const { setIsSavePaymentsVisible, isSavePaymentsVisible, showSavedPayments, hideCVV } = useMainContext();
  const formatCC = (val = '') => {
    const noSpace = val.replace(/\s/g, '');
    return noSpace.replace(/(.{4})/g, '$1 ').trim();
  };

  const onCreditCardInput = ({ target }) => {
    const { id, value } = target;
    const noSpace = value.replace(/\s/g, '');

    onCreditCardChanged({ target: { id, value: noSpace } });
  };
  const formatedCC = formatCC(cc);

  const renderSavedPayments = () => (
    <SavedPayments savedPayment="VISA" cvv={cvv} onFormInputChange={onFormInputChange} inputErrors={inputErrors} />
  );

  const renderNewCC = () => (
    <div>
      <div className="cc-detailes">
        <FormField
          errors={inputErrors}
          maxLength="19"
          onBlur={validateInputs}
          value={formatedCC}
          placeholder="Credit Card"
          onInput={onCreditCardInput}
          id="cc"
          ccIcon={ccIcon}
        >
          Card Number
        </FormField>
        <FormField
          errors={inputErrors}
          value={expiry}
          onChange={onFormInputChange}
          placeholder="MM/YY"
          id="expiry"
          onBlur={validateInputs}
        >
          Expiry
        </FormField>
        {hideCVV ? (
          ''
        ) : (
          <FormField
            errors={inputErrors}
            value={cvv}
            maxLength="4"
            onChange={onFormInputChange}
            placeholder="CVV"
            id="cvv"
            onBlur={validateInputs}
          >
            CVV
          </FormField>
        )}
      </div>
      {showSavedPayments ? (
        <div
          className="text-btn"
          onClick={() => {
            setIsSavePaymentsVisible(true);
          }}
        >
          saved cards
        </div>
      ) : (
        ''
      )}
    </div>
  );
  return showSavedPayments && isSavePaymentsVisible ? renderSavedPayments() : renderNewCC();
}

export default CreditCardDetails;
