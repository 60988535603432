import React from 'react';
import { FLOW_DATA, FLOW_TYPES } from '../helpers/flows';

export default function QuickFlowPicker({ flow, show }) {
  const { type, title } = FLOW_DATA[flow];
  const types = new Map(FLOW_TYPES);
  const flowType = types.get(type);

  return show ? (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content quick-flow-picker-modal">
        <div className="quick-flow-title animated pulse">{`${flowType} - ${title}`}</div>
      </div>
    </div>
  ) : (
    ''
  );
}
