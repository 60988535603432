export const validateCVV = (inputErrors, setInputErrors, formFields) => {
  const newErrors = inputErrors;
  if (formFields.cvv.trim() === '') {
    newErrors.cvv = 'CVV is required';
  } else if (!/^\d+$/.test(formFields.cvv)) {
    newErrors.cvv = 'CVV should contain numbers only';
  } else if (formFields.cvv.length > 4) {
    newErrors.cvv = 'CVV is too long';
  } else if (formFields.cvv.length < 3) {
    newErrors.cvv = 'CVV is too short';
  } else {
    newErrors.cvv = null;
  }
  setInputErrors({ ...newErrors });
};

export const validateCreditCard = (inputErrors, setInputErrors, formFields, newCreditType) => {
  const newErrors = inputErrors;
  if (formFields.cc.trim() === '') {
    newErrors.cc = 'Credit card is required';
  } else if (!/^\d+$/.test(formFields.cc)) {
    newErrors.cc = 'Credit card should contain numbers only';
  } else if (!newCreditType) {
    newErrors.cc = 'Credit number is invalid';
  } else {
    newErrors.cc = null;
  }
  setInputErrors({ ...newErrors });
};

export const validateExpiry = (inputErrors, setInputErrors, formFields) => {
  const newErrors = inputErrors;
  const [month, year] = formFields.expiry.split('/');
  if (year && (year.length !== 2 || !/^\d+$/.test(year))) {
    newErrors.expiry = 'Year must be composed of 2 digits';
  } else if (month && (month.length !== 2 || !/^\d+$/.test(month))) {
    newErrors.expiry = 'Month must be composed of 2 digits';
  } else if (formFields.expiry.trim() === '') {
    newErrors.expiry = 'Expiry is required';
  } else if (!formFields.expiry.includes('/')) {
    newErrors.expiry = 'Format should be mm/yy';
  } else if (parseInt(month, 10) > 12 || parseInt(month, 10) === 0) {
    newErrors.expiry = "Month isn't in range 1 - 12";
  } else if (parseInt(year, 10) < 21) {
    newErrors.expiry = 'Card has expired';
  } else if (year === '' || month === '') {
    newErrors.expiry = 'Missing month or year';
  } else {
    newErrors.expiry = null;
  }
  setInputErrors({ ...newErrors });
};

export const validateFormFields = (inputErrors, setInputErrors, formFields) => {
  const newErrors = inputErrors;
  if (formFields.email.trim() === '') {
    newErrors.email = 'Email is required';
  } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formFields.email)) {
    newErrors.email = 'Expected: name@domain.com';
  } else {
    newErrors.email = null;
  }
  if (formFields.firstName.trim() === '') {
    newErrors.firstName = 'First name is required';
  } else {
    newErrors.firstName = null;
  }
  if (formFields.lastName.trim() === '') {
    newErrors.lastName = 'Last name is required';
  } else {
    newErrors.lastName = null;
  }
  if (formFields.zipCode.trim() === '') {
    newErrors.zipCode = 'Zip code is required';
  } else {
    newErrors.zipCode = null;
  }
  setInputErrors({ ...newErrors });
};
